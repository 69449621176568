<template>
  <div>
    <div>
        <form @submit.prevent="storeTeam()" class="space-y-4 border-2 p-8">
            <div class=" flex flex-col space-y-2">
                <label for="title">Project Title</label>
                <input type="text" id="title" required v-model="bootcamp.title" />
            </div>
            <div class=" flex flex-col space-y-2">
                <label for="description">Project Description</label>
                <textarea id="description" required v-model="bootcamp.description"></textarea>
            </div>
            <div class=" flex flex-col space-y-2"> 
                <label for="batch_id">Batch</label>
                <select id="batch_id" required v-model="bootcamp.batch_id">
                    <option value="1">Alpha Batch</option>
                    <option value="2">Bravo Batch</option>
                    <option value="3">Charlie Batch</option>
                    <option value="4">Delta Batch</option>
                    <option selected value="5">Zulu Batch</option>
                </select>
            </div>
            <div class="border border-black p-4 grid grid-cols-2 gap-4 rounded !mt-12">
                <div v-for="(team, index) in bootcamp.teams" :key="index">
                    <select name="course" id="course" required v-model="team.course_id" class="text-sm">
                        <option v-for="course in courses.filter( item => item.is_available_instructor == 1)" :key="course.id" :value="course.id">{{course.title}}</option>
                    </select>
                    <input type="number" name="quota" id="quota" required v-model="team.quota" />
                </div>
            </div>
            <div>
                <button @click="addTrack()" class="rounded-md bg-blue-500 text-white p-2">Add track +</button>
            </div>
            <button type="submit" class="text-white font-bold bg-emerald-600 mt-12 rounded" style="padding:12px">Submit</button>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
    data() {
        return {
            bootcamp:{
                title: "",
                description: "",
                batch_id: "",
                teams:[
                    {
                        course_id: 1,
                        quota: 0
                    }
                ]
            },
            courses: [],
        }
    },
    computed:{
        selectedTracks() {
            let arr = [];
            this.bootcamp.teams.forEach(element => {
                arr.push(element.course_id)
            });
            return arr;
        },
    },
    methods:{
        async storeTeam(){
            let loader = this.$loading.show();
            try {
                await axios.post("admin/auth/teams/create", this.bootcamp)
                swal({
                    title: "Success",
                    text: "Bootcamp Team Created successfully"
                })
                this.bootcamp = {
                    title: "",
                    description: "",
                    batch_id: "",
                    teams:[
                        {
                            course_id: 1,
                            quota: 0
                        }
                    ]
                }
                loader.hide();
            } catch (error) {
                console.error(error);
                swal({
                    title: "Ooops!!!",
                    text: "Unable to create team at the moment. Please try again shortly"
                })
                loader.hide();
            }
        },
        async fetchCourses(){
            let loader = this.$loading.show();
            const { data } = await axios.get("courses");
            this.courses = data.payload
            loader.hide();
        },
        addTrack(){
            const obj = {course_id: "", quota: ""}
            this.bootcamp.teams.push(obj);
        }
    },
    mounted(){
        this.fetchCourses();
    }
}
</script>

<style scoped>

input , select, textarea {
border-radius: 6px;
padding: 4px;
margin-top: 6px;
margin-bottom: 12px;
border: 1px solid;
}

label {
font-weight: 600;
}

input[type='checkbox']{
margin-right: 6px;
}

</style>