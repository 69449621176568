<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/"
      >
        Terra Learning
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                SIDE HUSTLE
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center">
            <router-link
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
              :to="{ name: 'CohortEight' }"
            >
              <i class="fas fa-cogs text-blueGray-300 mr-2 text-sm"></i>
              New Cohort
            </router-link>
          </li>
          <details class="items-center">
            <summary
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            >
              <i class="fas fa-caret-down text-blueGray-300 mr-2 text-sm"></i>
              STATS
            </summary>
            <div class="pl-4 ml-3">
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'Dashboard' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Dashboard
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'Charts' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Charts
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'Referrals' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Referrals
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'ExportData' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Export Data (CSV)
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'PaymentStats' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Payments Stats
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'AllPayments' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  All Payments
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'MarketingData' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  COHORT EIGHT REG DATA
                </router-link>
              </li>
            </div>
          </details>
          <li class="items-center">
            <router-link
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
              :to="{ name: 'Users' }"
            >
              <i class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"></i>
              Find Users
            </router-link>
          </li>
          <details class="items-center">
            <summary
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            >
              <i class="fas fa-caret-down text-blueGray-300 mr-2 text-sm"></i>
              Learning Data
            </summary>
            <div class="pl-4 ml-3">
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'UserActivityPage' }"
                >
                  <i class="fas fa-usps text-blueGray-300 mr-2 text-sm"></i>
                  User Activity
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'QuizStatsPage' }"
                >
                  <i class="fas fa-usps text-blueGray-300 mr-2 text-sm"></i>
                  Quiz Stats
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'LessonStatsPage' }"
                >
                  <i class="fas fa-usps text-blueGray-300 mr-2 text-sm"></i>
                  Watched Lessons Stats
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'FeedbackStats' }"
                >
                  <i class="fas fa-usps text-blueGray-300 mr-2 text-sm"></i>
                  User Reviews
                </router-link>
              </li>
            </div>
          </details>
          <details class="items-center">
            <summary
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            >
              <i class="fas fa-caret-down text-blueGray-300 mr-2 text-sm"></i>
              Transaction
            </summary>
            <div class="pl-4 ml-3">
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'UpdatePaymentPage' }"
                >
                  <i
                    class="fas fa-comment-alt text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Update User Payment
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'CreatePaymentPage' }"
                >
                  <i class="fas fa-bell text-blueGray-300 mr-2 text-sm"></i>
                  Create Payment Record
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'PendingPaymentsPage' }"
                >
                  <i class="fas fa-bell text-blueGray-300 mr-2 text-sm"></i>
                  Update Pending Payments
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'PendingCommitmentPage' }"
                >
                  <i class="fas fa-bell text-blueGray-300 mr-2 text-sm"></i>
                  Update Pending Commitment Fee Payments
                </router-link>
              </li>
            </div>
          </details>
          <details class="items-center">
            <summary
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
            >
              <i class="fas fa-caret-down text-blueGray-300 mr-2 text-sm"></i>
              Bootcamp
            </summary>
            <div class="pl-4 ml-3">
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'CreateTeam' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  ADD a Startup Project
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'StartUpTeams' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  All Bootcamp Startup Projects
                </router-link>
              </li>
              <li class="items-center">
                <router-link
                  class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  :to="{ name: 'ReviewTeamLead' }"
                >
                  <i
                    class="fas fa-newspaper text-blueGray-300 mr-2 text-sm"
                  ></i>
                  Review Team Lead Applications
                </router-link>
              </li>
            </div>
          </details>
          <li class="items-center">
            <router-link
              class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
              :to="{ name: 'ReferralEarnings' }"
            >
              <i class="fas fa-cogs text-blueGray-300 mr-2 text-sm"></i>
              Referral Earnings
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      collapseShow: "hidden",
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    NotificationDropdown,
    UserDropdown,
  },
};
</script>
