<template>
  <div>
    <details class="mb-12" v-if="weekly?.length>0">
        <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Ambassadors Referrals Weekly Data (With Raven) ({{`From ${start_date} - ${end_date}`}})</summary>
        <div>
            <button @click="convertToCSV(weekly, `ambassador weekly with raven${start_date}-${end_date}`)" class="ml-auto p-2 bg-red-400 rounded mb-4 font-semibold">
                Download Data
            </button>
            <table class="w-full table-auto">
                <tr>
                        <th>Referral ID</th>
                        <th>Count</th>
                </tr>
                <tbody>
                    <tr v-for="(referral, index) in weekly" :key="index">
                        <td>{{referral.referred_by}}</td>
                        <td>{{referral.count}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </details>
    <details class="mb-12" v-if="weekly_no_raven?.length>0">
        <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Ambassadors Referrals Weekly Data (Without Raven) ({{`From ${start_date} - ${end_date}`}})</summary>
        <div>
            <button @click="convertToCSV(weekly_no_raven, `ambassador weekly without raven${start_date}-${end_date}`)" class="ml-auto p-2 bg-red-400 rounded mb-4 font-semibold">
                Download Data
            </button>
            <table class="w-full table-auto">
                <tr>
                        <th>Referral ID</th>
                        <th>Count</th>
                </tr>
                <tbody>
                    <tr v-for="(referral, index) in weekly_no_raven" :key="index">
                        <td>{{referral.referred_by}}</td>
                        <td>{{referral.count}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </details>
    <details class="mb-12 space-y-8" v-if="has_raven && has_raven.length>0">
        <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Ambassadors Referrals Data (With Raven)</summary>
        <div>
            <table class="w-full table-auto">
                <tr>
                        <th>Referral ID</th>
                        <th>Count</th>
                </tr>
                <tbody>
                    <tr v-for="(referral, index) in has_raven" :key="index">
                        <td>{{referral.referred_by}}</td>
                        <td>{{referral.count}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </details>
    <details class="mb-12 space-y-8" v-if="no_raven && no_raven.length>0">
        <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Ambassadors Referrals Data (Without Raven)</summary>
        <div>
            <table class="w-full table-auto">
                <tr>
                        <th>Referral ID</th>
                        <th>Count</th>
                </tr>
                <tbody>
                    <tr v-for="(referral, index) in no_raven" :key="index">
                        <td>{{referral.referred_by}}</td>
                        <td>{{referral.count}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </details>
    <div v-if="no_raven && no_raven.length == 0" class="font-bold mb-12 space-y-8">
        No Records to show
    </div>
    <div  v-if="!no_raven" class="font-bold ">
        Loading...
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            has_raven: null,
            no_raven: null,
            weekly: null,
            weekly_no_raven: null,
            start_date: null,
            end_date: null,
        }
    },
    computed:{
        // 
    },
    methods:{
        async fetchAmbassadorCount(){
            try {
                this.user = null;
                axios.get("admin/auth/referral/ambassadors")
                .then((res) => {
                    this.has_raven = res.data.payload.referrals;
                    this.no_raven = res.data.payload.no_raven;
                    this.weekly = res.data.payload.weekly;
                    this.weekly_no_raven = res.data.payload.weekly_no_raven;
                    this.start_date = res.data.payload.start_of_week;
                    this.end_date = res.data.payload.end_of_week;
                })
                .catch((err) => {
                    console.log(err)
                    this.ambassadors = [];
                    this.referrals = [];
                })
            } catch (error) {
                console.log(error)
            }
        },
        convertToCSV(arr, type) {
            const array = [Object.keys(arr[0])].concat(arr)

            const result = array.map(it => {
                return Object.values(it).toString()
            }).join('\n')
            const TIME_STAMP = new Date().toISOString();
            const filename = `${type??''}${TIME_STAMP}`;
            this.handleDownload(result, filename)
        },
        handleDownload(file, file_name) {
            const newBlob = new Blob([file], { type: 'application/octet-stream' })
            let url = URL.createObjectURL(newBlob)
            var a = document.createElement("a")
            a.href =  url;
            a.download = file_name + '.csv';
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0);
        },
    },
    mounted(){
        this.fetchAmbassadorCount();
    }
}
</script>

<style scoped>
table td {
    border: 1px solid #ddd;
    cursor: pointer;
  padding: 8px;
}

table th{
    background-color: #e4e4e4;
    text-align: left;
    padding: 8px;
}
table tbody tr:nth-child(even){
    background-color: #f2f2f2;
}

table tbody tr:hover {
    background-color: #ddd;
}

</style>