<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <template v-if="!isLoading && modules?.length > 0">
        <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4">
            <tr>
                <th>S/N</th>
                <th>Title</th>
                <th>Vimeo Url</th>
                <th>Format</th>
                <th>Week</th>
                <th>Course Title</th>
            </tr>
            <tbody class="space-y-4">
                <tr v-for="(module, index) in modules" :key="module.id">
                    <td>{{index + 1}}</td>
                    <td>{{module?.lesson?.title ?? ""}}</td>
                    <td>
                        <input
                            @keyup="updateUrl(module.id, module.vimeo_url)"
                            type="url" name="vimeo_url" id="vimeo_url"
                            v-model="module.vimeo_url"
                            placeholder="Paste vimeo link here..."
                        />
                    </td>
                    <td>{{module?.format ?? ""}}</td>
                    <td>{{module?.lesson?.week ?? ""}}</td>
                    <td>{{module?.lesson?.course?.title ?? ""}}</td>
                </tr>
            </tbody>
        </table>
        <button @click="updateAllUrl()" class="bg-emerald-600 p-4 text-white rounded-md my-6">Update All</button>
    </template>
    <template v-if="!isLoading && modules?.length === 0">
        <h1 class="font-semibold text-lg my-4">No Data to display</h1>
    </template>
  </main>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
export default {
    data() {
        return {
            modules: [],
            newModules: [],
            isLoading: false,
        }
    },
    methods: {
        async fetchModules(week, course) {
            this.isLoading = true;
            try {
                const payload = {
                    week: week,
                    course_id: course
                };
                const { data } = await axios.post("admin/auth/module/get", payload)
                this.modules = data.payload;
                this.isLoading = false;
            } catch (error) {
                console.log(error)
                this.isLoading = false;
                this.modules = [];
            }
        },
        updateUrl(id, url) {
            console.log(id, url)
            let module = {
                id,
                vimeo_url: url
            }
            this.newModules.push(module)
            // eslint-disable-next-line no-unused-vars
            const unique = [...new Map(this.newModules.map((item, key) => [item['id'], item])).values()]
            this.newModules = unique;
        },
        async updateAllUrl() {
            try {
                this.isLoading = true;
                await axios.post("admin/auth/module/update", { modules:this.newModules });
                await this.fetchModules(this.$route?.params?.week , this.$route?.params?.course_id);
                this.newModules = [];
                this.isLoading = false;
            } catch (error) {
                console.log(error)
                this.isLoading = false;
                swal({
                    title: "Oops!",
                    text: "Unable to update. Please try again"
                })
            }
        }
    },
    mounted() {
        if (this.$route?.params?.week && this.$route?.params.course_id) {
            this.fetchModules(this.$route.params.week , this.$route.params.course_id);
        } else {
            this.$router.push({name: "SelectTrackWeek"});
        }
    }
}
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
text-align: left;
}

table {margin: 12px 0;}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>