<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">Send Push Notification Updates</h1>
    <form @submit.prevent="sendNotification()" class="my-4 space-y-4 grid grid-flow-row gap-4">
        <label for="title" class="font-semibold mb-2">
            Notification Title:
        </label>
        <input type="text" placeholder="Enter notification title here..." name="title" id="title" v-model="payload.title" class="mb-2" />
        <label for="body" class="font-semibold mb-2">
            Notification Body:
        </label>
        <input type="text" placeholder="Enter notification body here..." name="body" id="body" v-model="payload.body" class="mb-2" />
        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4">Dispatch Notification</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import swal from "sweetalert";
import axios from "axios";

const payload = ref({
    title: "",
    body: "",
})

const isLoading = ref(false);

// eslint-disable-next-line
const sendNotification = async () => {
    if (payload.value.title.trim().length === 0 || payload.value.body.trim().length === 0) {
        swal({
        title: "Oops",
        text: "Fill in all fields"
       })
       return;
    }
    isLoading.value = true;
    try {
       await axios.post("admin/auth/push", payload.value);
       swal({
        title: "Success",
        text: "Notification Dispatched"
       })
       payload.value.title = "";
       payload.value.body = "";
       isLoading.value = false;
    } catch (error) {
        console.log(error)
        swal({
        title: "Oops!",
        text: "Unable to dispatch notification. Please try again later."
       })
       isLoading.value = false;
    }
};


</script>


<style scoped>
input, select {
border-radius: 7px;
}
</style>