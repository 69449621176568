<template>
  <main>
    <div>
        <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
        <h1 class="font-medium text-2xl my-4">Select The Track And Week</h1>
        <details v-for="course in courses" :key="course.id" class="my-1">
            <summary>{{course?.title}}</summary>
             <button 
                @click="$router.push({name: 'UpdateVimeoLinks', params:{ week: week, course_id: course.id } })"
                v-for="week in [1,2,3,4]" :key="week"
                class="bg-emerald-600 p-2 text-white rounded-md mr-2">
                {{`Week ${week}`}}
             </button>
        </details>
    </div>

  </main>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            courses : [],
            isLoading: false,
        }
    },
    methods: {
        async fetchCourses() {
            this.isLoading = true;
            const { data } = await axios.get("/courses")
            this.courses = data.payload;
            this.isLoading = false;
        }
    },
    mounted() {
        this.fetchCourses();
    }

}
</script>

<style>

</style>