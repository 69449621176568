<template>
  <div
    id="main"
    :class="`md:px-[80px] px-5 pt-[100px] ${
      show ? 'md:ml-[350px] px-[0px]' : ''
    }`"
  >
    <div class="w-full flex justify-between mt-1">
      <div
        @click="$router.back()"
        class="font-body font-semibold text-primary-green mb-8"
      >
        <button>
          <span
            class="iconify inline text-xl"
            data-icon="akar-icons:arrow-back-thick"
          ></span>
          Go back
        </button>
      </div>
      <!-- <select
        id="filter"
        @change="handleFilter"
        v-model="filter"
        class="bg-gray-50 border w-[200px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 mr-[60px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        <option value="" selected>filter</option>
        <option value="audio">Audio</option>
        <option value="video">Video</option>
        <option value="doc">Document</option>
        <option value="asc">Learning Path</option>
      </select> -->
    </div>
    <div
      id="page"
      class="bg-[#fafafa] md:w-[95%] w-[100%] min-h-[100vh] md:p-10 p-2"
      v-if="tasks"
    >
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">S/N</th>
            <th scope="col" class="px-6 py-3">Task</th>
            <!-- <th scope="col" class="px-6 py-3">instruction</th> -->
            <th scope="col" class="px-6 py-3">Learning Path</th>
            <th scope="col" class="px-6 py-3">Week</th>
            <th scope="col" class="px-6 py-3">Status</th>
            <th scope="col" class="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
            v-for="(data, index) in tasks"
            :key="data?.id"
          >
            <td class="px-6 py-4">{{ index + 1 }}</td>
            <td
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 dark:text-white"
            >
              <!-- {{ handleText(data?.task) }} -->
              {{ data?.instruction }}
            </td>
            <!-- <td class="px-6 py-4">{{ data?.instruction }}</td> -->
            <td class="px-6 py-4">
              {{ getCourse(data?.course_id) }}
            </td>
            <td class="px-6 py-4">{{ data?.week }}</td>
            <td class="px-6 py-4">
              <button
                @click="showModal(data?.id, 'edit', index)"
                class="bg-blue-500 px-5 text-white py-2 rounded-md"
              >
                Edit
              </button>
            </td>
            <!-- <td class="px-6 py-4">
              <span
                class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                >Completed</span
              >
            </td> -->
            <td class="px-6 py-4">
              <button
                @click="showModal(data?.id, 'delete')"
                class="bg-red-500 px-5 text-white py-2 rounded-md"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="modal" class="bg-white px-20 py-10 rounded-lg">
        <div v-if="edit" id="edit">
          <form class="grid gap-4 grid-flow-row my-8 border rounded-md p-4">
            <div class="flex flex-col space-y-2">
              <div class="flex flex-col space-y-2">
                <label for="description">Task</label>
                <textarea
                  type="text"
                  required
                  rows="5"
                  id="instruction"
                  class="border border-blue-600 bottom-1 rounded-lg px-3 pt-3"
                  v-model="payload.instruction"
                  placeholder="Enter Task Intruction"
                ></textarea>
              </div>
              <label for="title">Instructions</label>
              <textarea
                type="text"
                id="task"
                rows="2"
                class="border border-blue-600 bottom-1 rounded-lg px-3 pt-3"
                placeholder="Enter Task information"
                v-model="payload.task"
                required
              ></textarea>
            </div>

            <div class="flex flex-col space-y-2">
              <label for="week">Select Week</label>
              <select
                name="week"
                id="week"
                v-model="payload.week"
                class="w-fit p-1 rounded-md text-sm"
              >
                <option v-for="week in [1, 2, 3, 4]" :key="week" :value="week">
                  Week {{ week }}
                </option>
              </select>
            </div>

            <!-- <div class="flex flex-col space-y-2">
                    <label for="url">Video URL</label>
                    <input type="url" required v-model="liveClass.url" id="url" placeholder="Enter the url to the live class recording Eg. https://drive.google.com/file/d/1eZjdmWsMfaAkcBLcjPA9ZnbtpzDS9Gsq/view?usp=sharing" />
                </div> -->
            <div class="flex flex-col space-y-2">
              <label for="batch_id">Batch</label>
              <select
                name="batch_id"
                id="week"
                value="Select Batch"
                required
                v-model="payload.batch_id"
              >
                <option value="">Select Batch</option>
                <option value="1">Alpha Batch</option>
                <option value="2">Bravo Batch</option>
                <option value="3">Charlie Batch</option>
                <option value="4">Delta Batch</option>
                <option value="6">Latest</option>
              </select>
            </div>
          </form>
          <div class="flex items-center justify-center" id="btn-grp">
            <button
              id="red"
              @click="cancel"
              class="bg-red-600 px-8 py-2 text-white"
            >
              No
            </button>
            <button
              id="green"
              @click="update"
              class="bg-green-600 px-8 py-2 text-white rounded-lg"
            >
              Yes
            </button>
          </div>
        </div>
        <div v-else>
          <h3 class="mb-4 font-semibold text-lg">
            Are you sure you want to delete
          </h3>
          <div class="flex items-center justify-center" id="btn-grp">
            <button
              id="red"
              @click="cancel"
              class="bg-red-600 px-8 py-2 text-white"
            >
              No
            </button>
            <button
              id="green"
              @click="proceed"
              class="bg-green-600 px-8 py-2 text-white rounded-lg"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div id="drop" class="h-full" @click="cancel"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

// import ufs from "url-file-size";
export default {
  data() {
    return {
      tasks: null,
      courses: null,
      lessons: null,
      newData: null,
      hashCourse: null,
      filter: "",
      id: "",
      admin: false,
      edit: false,
      payload: {
        task: "",
        instruction: "",
        batch_id: "",
        week: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  mounted() {
    let loader = this.$loading.show();
    if (this.user.email === "admin@sidehustle.ng") {
      this.admin = true;
    }

    this.fetchCourse().then((data) => {
      this.courses = data.payload;
      console.log(this.courses);
      this.hashCourse = this.reducer(this.courses);
      this.fetchTasks().then((data) => {
        this.tasks = data.payload;
        console.log(this.tasks);
        this.newData = this.check(data.payload.all);
        loader.hide();
      });
    });
  },
  methods: {
    check(item) {
      return this.admin
        ? item
        : item.filter(
            (e) =>
              this.getCourse(e.course_id).toLowerCase() ===
              this.user.course.title.toLowerCase()
          );
    },
    reducer(item) {
      const hashCourse = item.reduce(function (map, obj) {
        map[obj.id] = obj.title;
        return map;
      }, {});
      return hashCourse;
    },

    async fetchCourse() {
      const res = await axios.get("courses");
      return res.data;
    },
    handleText(arrText) {
      return JSON.parse(arrText).join();
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    toggleProgress() {
      document.getElementById("modal").classList.toggle("active");
      // document.body.stye.overflowY = "hidden";
      document.getElementById("drop").classList.toggle("active");
    },

    async fetchTasks() {
      const res = await axios.get("auth/tasks/all");
      return res.data;
    },
    getCourse(id) {
      if (this.courses) {
        const crse = this.courses.filter((course) => course.id === id);
        return crse[0]?.title;
      }
    },
    showModal(id, path, index) {
      if (path == "edit") {
        this.edit = true;
        this.payload = {
          ...this.tasks[index],
          task: this.handleText(this.tasks[index].task),
          course_id: this.tasks[index].course_id,
        };
      }
      this.topFunction();
      this.toggleProgress();
      this.id = id;
    },

    async proceed() {
      this.toggleProgress();
      let loader = this.$loading.show();
      const res = await axios.post("tasks/delete/" + this.id);
      if (res.status) {
        this.tasks = this.tasks.filter((dat) => dat?.id !== this.id);
        this.id = "";
        loader.hide();
      } else {
        this.id = "";
        loader.hide();
      }
    },

    async update() {
      this.toggleProgress();
      let loader = this.$loading.show();
      const res = await axios.post("tasks/update", {
        ...this.payload,
        id: this.id,
      });
      if (res.status) {
        this.id = "";
        (this.payload = {
          task: "",
          instruction: "",
          batch_id: "",
          week: 1,
        }),
          loader.hide();
      } else {
        this.id = "";
        (this.payload = {
          task: "",
          instruction: "",
          batch_id: "",
          week: 1,
        }),
          loader.hide();
      }
    },
    cancel() {
      this.id = "";
      this.edit = false;
      this.toggleProgress();
    },
    handleFilter() {
      // e.preventDefault();
      this.filterFun();
    },
    filterFun() {
      let loader = this.$loading.show();
      //   if (this.filter === "audio") {
      //     this.fetchLessons().then((res) => {
      //       this.tasks = this.check(res.payload);
      //       this.tasks = this.tasks.filter((a) => a.format === "audio");
      //       loader.hide();
      //     });
      //   } else if (this.filter === "video") {
      //     this.fetchLessons().then((res) => {
      //       this.tasks = this.check(res.payload);
      //       this.tasks = this.tasks.filter((a) => a.format === "video");
      //       console.log(this.tasks);
      //       loader.hide();
      //     });
      //   } else if (this.filter === "doc") {
      //     this.fetchLessons().then((res) => {
      //       this.tasks = this.check(res.payload);
      //       this.tasks = this.tasks.filter((a) => a.format === "doc");
      //       loader.hide();
      //     });
      //   }
      if (this.filter === "asc") {
        this.fetchLessons().then((res) => {
          this.tasks = this.check(res.payload);
          this.tasks = this.tasks.sort((a, b) => a.course_id - b.course_id);
          console.log(this.tasks);
          loader.hide();
        });
      } else {
        this.fetchLessons().then((res) => {
          this.tasks = this.check(res.payload);
          loader.hide();
        });
      }
    },
  },
};
</script>

<style scoped>
#filter {
  position: sticky;
  top: 500px;
  margin-bottom: 20px;
}

#modal {
  position: fixed;
  top: 50%;
  padding: 10px 25px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.85);
  display: none;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  z-index: 100000;
}

#drop {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: auto;
  /* overflow-y: hidden; */
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

#drop.active {
  display: block;
}

#modal.active {
  display: block;
}
#card-grp {
  display: flex;
  flex-wrap: wrap;
}

#card-grp > * {
  flex: 0 0 25%;
}

#green {
  border-radius: 0px;
}

#card {
  margin-bottom: 20px;
}

#btn-grp {
  gap: 5px;
}

@media (max-width: 810px) {
  #card {
    width: 90%;
    margin: 5px auto;
  }
}
</style>
