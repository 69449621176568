<template>
  <main>
    <h1 class="font-semibold text-2xl mb-6">Create Payment Record</h1>
    <div class="m-6 p-4 rounded border">
        <form @submit.prevent="createPayment" class="">
            <div class="flex flex-col">
                <label for="email" class="font-semibold text-lg">User Email Address</label>
                <input required type="email" v-model="payload.email" name="email" id="email" placeholder="johndoe@mail.xyz" class="p-2 rounded-sm border"  />
            </div>
            <div class="flex flex-col my-6">
                <label for="payment_id" class="font-semibold text-lg">Item</label>
                <select name="payment_id" id="payment_id" v-model="payload.payment_id">
                    <option value="1">Basic Certificate</option>
                    <option value="2">Standard Certificate</option>
                    <option value="3">Premium Certificate</option>
                    <option value="7">Standard Portfolio Bootcamp</option>
                    <option value="8">Premium Portfolio Bootcamp</option>
                    <option value="9">Startup Bootcamp</option>
                    <option value="0">Commitment Fee</option>
                </select>
            </div>
            <div class="flex flex-col my-6">
                <label for="payment_id" class="font-semibold text-lg">User Batch</label>
                <select name="payment_id" id="payment_id" v-model="payload.batch_id">
                    <option value="1">Alpha Clan</option>
                    <option value="2">Bravo Clan</option>
                    <option value="3">Charlie Clan</option>
                    <option value="4">Delta Clan</option>
                    <option value="5">Zulu Clan</option>
                    <option value="6">Alpha Clan 2023</option>
                    <option value="7">MY 1</option>
                    <option value="8">MY 2</option>
                    <option value="9">MY 3</option>
                    <option value="10">MY 4</option>
                    <option value="11">MY 5</option>
                    <option value="12">JN 1</option>
                    <option value="13">JN 2</option>
                    <option value="14">JN 3</option>
                    <option value="15">JN 4</option>
                </select>
            </div>
            <div class="flex flex-col my-6">
                <label for="amount" class="font-semibold text-lg">Transaction Amount</label>
                <input required type="number" v-model="payload.amount" name="amount" id="amount" min="0" max="10000" class="p-2 rounded-sm border" />
            </div>
            <button type="submit" class="p-4 bg-emerald-600 text-white">Update Payment</button>
        </form>
    </div>
  </main>
</template>

<script setup>
import { reactive } from "@vue/reactivity";
// import { computed} from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
// import { useStore } from "vuex";
// const store = useStore();


const payload = reactive({
    email: '',
    payment_id:1,
    batch_id:1,
    amount:'',
});




// eslint-disable-next-line no-unused-vars
const createPayment = async () => {
    try {
        await axios.post('admin/auth/payment/create', payload);
        swal({
            title: 'Success',
            text: 'Payment Record Saved Successfully'
        })
        payload.email = ''
        payload.amount = ''
        return;
    } catch (error) {
        console.error(error);
        swal({
            title: 'Oops!',
            text: error?.response?.data?.payload ?? "Unable to save this transaction. Please try again later."
        })
    }
}

// onMounted(()=> {
//     if (!user?.value?.roles?.includes('superadmin') && !user?.value?.roles?.includes('accounts')) {
//         swal({
//             title: "Warning!!!",
//             text: 'Unauthorized'
//         })
//         return router.push({name: 'AllPayments'});
//     }
// })
</script>

<style>

</style>