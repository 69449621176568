<template>
  <main>
    <div class="">
      <p>Total Downloads: <strong>{{ arrReduce(stats?.weekly_downloads) }}</strong></p>
      <p>Total Usage: <strong>{{ arrReduce(stats?.weekly_usage) }}</strong></p>
    </div>
    <div class="w-full mb-12" v-if="true">
        <line-chart 
            :users="stats?.weekly_downloads"
            :baselines="stats?.weekly_usage"
        /> 
    </div>
    <div class="grid grid-cols-2 gap-12">
        <div class="mb-12 flex-col items-center ">
            <h2 v-if="stats?.grouping?.country && stats?.grouping?.country.length > 0"  class="font-semibold text-lg text-blueGray-700 mb-4">Grouped by country</h2>
            <pie-chart :chartVal="stats?.grouping?.country" class=" mx-auto" />
        </div>
        <div class="mb-12 flex-col items-center ">
            <h2 v-if="stats?.grouping?.operating_system && stats?.grouping?.operating_system.length > 0"  class="font-semibold text-lg text-blueGray-700 mb-4">Grouped by Operating System</h2>
            <pie-chart :chartVal="stats?.grouping?.operating_system" class=" mx-auto" />
        </div>
    </div>
  </main>
</template>

<script>
import LineChart from "@/components/Cards/LineChart.vue";
import PieChart from "@/components/Cards/PieChart.vue";
import axios from "axios";
export default { 
  components: {
    LineChart,
    PieChart,
  },
  data(){
    return {
        stats: null
    }
  },
  methods:{
    arrReduce(arr){
      try {
        var total = 0;
        arr.forEach(item => {
            total += item.count;
        });
        return total
      } catch (error) {
        return 0
      }
    },
    async fetchDownloadStats(){
        let loader = this.$loading.show();
        try {
            const {data} = await axios.get("downloads")
            this.stats = data.payload
            loader.hide();
        } catch (error) {
            console.error(error);
            loader.hide();
        }
    }
  },
  mounted(){
    this.fetchDownloadStats();
  }
}
</script>

<style>

</style>