<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">
        Update Internship Site Settings
    </h1>
    <form @submit.prevent="updateSettings()" class="my-4 grid grid-cols-2 gap-x-8 gap-y-4">
        <div>
            <label for="slack_workspace_link" class="font-semibold my-2">
                Slack Link:
            </label>
            <input type="url" name="slack_workspace_link" id="slack_workspace_link" v-model="payload.slack_workspace_link" class="mb-2" />
        </div>

      <fieldset class="col-span-2 grid grid-cols-3 gap-8 border-2 p-6 pt-0">
            <legend class="text-lg font-medium">Payment Gateway Switches</legend>
            <div class="flex flex-col items-start pt-4">
                <label for="flutter_card_payment" class="my-2">
                    Flutterwave Payment:
                </label>
                <label for="flutter_card_payment" class="switch">
                    <input type="checkbox"  name="flutter_card_payment" id="flutter_card_payment" v-model="payload.flutter_card_payment">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.flutter_card_payment ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="raven_payment_active" class="my-2">
                    WEMA Bank Transfer:
                </label>
                <label for="wema_bank_payment" class="switch">
                    <input type="checkbox"  name="wema_bank_payment" id="wema_bank_payment" v-model="payload.wema_bank_payment">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.wema_bank_payment ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="raven_bank_payment" class="my-2">
                    Raven Bank Transfer:
                </label>
                <label for="raven_bank_payment" class="switch">
                    <input type="checkbox"  name="raven_bank_payment" id="raven_bank_payment" v-model="payload.raven_bank_payment">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.raven_bank_payment ? "Enabled" : "Disabled"}}</span>
            </div>
        </fieldset>
        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4 col-span-2">Update Settings</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import swal from "sweetalert";
import axios from "axios";

// eslint-disable-next-line
const isLoading = ref(false);
// eslint-disable-next-line
const payload = ref({
    slack_workspace_link: "",
    raven_bank_payment: "",
    flutter_card_payment: "",
    wema_bank_payment: ""
})

const fetchSiteSettings = async () => {
    try {
        const {data} = await axios.get("admin/auth/bootcamp/settings");
        const res = data.payload
        const settings = payload.value;
        settings.slack_workspace_link = res.slack_workspace_link
        settings.wema_bank_payment = !!res.wema_bank_payment
        settings.flutter_card_payment = !!res.flutter_card_payment
        settings.raven_bank_payment = !!res.raven_bank_payment
    } catch (error) {
        console.log(error)
        swal({
            title: "Oops!",
            text: "Please refresh this page"
        })
    }
}

// eslint-disable-next-line
const updateSettings = async () => {
    try {
        isLoading.value = true;
        const { data } = await axios.post("admin/auth/bootcamp/settings", payload.value);
        console.log(data.payload)
        swal({
            title: "Success!",
            text: "Settings Updated Successfully"
        })
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        console.log(error)
        swal({
            title: "Oops!",
            text: "Please try again later"
        })
    }
}

onMounted(() => {
    fetchSiteSettings();
})
</script>


<style scoped>
input, select {
border-radius: 7px;
padding: 4px 8px;
font-size: 14px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>