<template>
  <main>
    <h1 class="mb-4 font-semibold text-xl">Bootcamp Waitlist Data</h1>
    Total Entries: <strong>{{ stats?.all?.length ?? "0" }}</strong> <button @click="downloadFile(stats.all, 'Bootcamp Waitlist')" class="px-4 py-2 bg-blue-600 text-white font-medium text-sm rounded-md mx-6">Download Data</button>
    <div class="w-full mb-12">
        <line-chart 
        :users="stats.weekly_data" />
    </div>
    <div class="mb-12 flex-col items-center ">
        <h2 v-if="stats?.by_track?.length > 0"  class="font-semibold text-lg text-blueGray-700 mb-4">By Track</h2>
        <pie-chart :chartVal="stats.by_track" class=" mx-auto" />
    </div>
  </main>
</template>

<script>
import PieChart from "@/components/Cards/PieChart.vue";
import LineChart from "@/components/Cards/LineChart.vue";
import axios from 'axios'
import {download} from "@/helpers/csvDownloader.js"
export default {
    data(){
        return {
            stats:[],
        }
    },
    components: {
        PieChart,
        LineChart,
    },
    methods:{
        downloadFile(file, file_name){
            download(file, file_name)
            return;
        },
        async fetchBootcampStats(){
            let loader = this.$loading.show();
            const {data} = await axios.get("admin/auth/bootcamp/waitlist")
            this.stats = data.payload
            loader.hide()
        }
    },
    mounted(){
        this.fetchBootcampStats();
    }
}
</script>

<style>

</style>