<!-- eslint-disable vue/no-v-for-template-key -->
<template>
    <main>
      <h1></h1> 
      <div class="grid grid-flow-col gap-6 my-6">
          <button @click="fetchPendingCommitments(item), payment_channel = item" :class="payment_channel == item ? 'bg-green-400' : '' " class="rounded-md p-4 bg-blue-400 text-white" v-for="(item, index) in payment_channels" :key="index"> {{ item == 'wema_bank_account'? 'Bank Trasfers' : item }} </button>
      </div>
      <div>
          <table class="table w-full mx-auto px-6">
              <thead class="bg-gray-300 font-semibold">
                  <td>S/N</td>
                  <td>Reference ID</td>
                  <td>Amount</td>
                  <td>Time Stamp</td>
                  <td>Payment Channel</td>
                  <td>Action</td>
                  <td>Action II</td>
              </thead>
              <tbody v-if="payments" >
                  <template v-for="(payment, index) in payments" :key="index" >
                      <tr class="relative group cursor-pointer border-b" v-if="((index + 1) < (currentPage * 20) && (index + 1 >= ((currentPage - 1) * 20)) )">
                          <td>{{ (index + 1) }}</td>
                          <td>{{payment.reference_id ?? ""}}</td>
                          <td>{{payment.amount ?? ""}}</td>
                          <td>{{ `${timeParser(payment.updated_at)}` ?? ""}}</td>
                          <!-- <td>{{ new Date(payment.updated_at).toLocaleString() ?? ""}}</td> -->
                          <td>{{payment.payment_channel ?? ""}}</td>
                          <td><button @click="updatePendingCommitmentFee(payment.reference_id)" class="text-white p-3 font-semibold rounded-md bg-green-400">Confirm</button></td>
                          <td><button @click="deleteCommitmentFee(payment.reference_id)" class="text-white p-3 font-semibold rounded-md bg-red-400">Delete</button></td>
                          <div class="rounded-lg p-3 bg-white shadow-xl hidden group-hover:flex flex-col space-y-7 absolute -top-10 myLeft z-50">
                              <strong>{{ `${payment.user.first_name} ${payment.user.last_name}`}}</strong>
                              <small>{{ `${payment.user.email}`}}</small>
                              <small>{{ `${payment.user.phone_number}`}}</small>
                              <ul class="text-sm list-disc list-inside">
                                  <!-- <li>{{ `${payment.payment.level} ${payment.payment.type}`}} {{ `(&#8358; ${payment.payment.price})`}}</li> -->
                                  <span> User confirmed payment @ <strong>{{ new Date(payment.updated_at).toLocaleString() ?? ""}}</strong> </span>
                              </ul>
                          </div>
                      </tr>
                  </template>
              </tbody>
          </table>
          <div class="w-fit ml-auto space-x-2 p-3" v-if="payments?.length > 20">
          <button
              @click="currentPage = page"
              v-for="page in (Math.ceil(payments?.length / 20))"
              :key="page"
              class="text-body-text font-medium text-xs font-body px-2 py-1" :class="page == currentPage ? 'bg-gray-200' : 'bg-gray-400'">{{page}}</button>
          </div>
          <div v-if="payments && payments?.length < 1">
              <h1 class="text-center my-2 w-full font-medium text-sm">There are no pending transactions from the last 24 hours</h1>
          </div>
      </div>
    </main>
  </template>
  
  <script setup>
  import { reactive, ref } from "@vue/reactivity";
  import { inject, onMounted } from "@vue/runtime-core";
  import axios from "axios";
  import moment from 'moment';
  
  
  
  const $loading = inject("$loading");
  
  
  
  // eslint-disable-next-line no-unused-vars
  const currentPage = ref(1);
  // eslint-disable-next-line no-unused-vars
  const timeParser = (time) => {
      var a = moment(time);
      var b = moment(new Date());
      let diff =  b.diff(a, 'hours') // 1
      if (diff < 23) {
          return`${24 - diff} hours left`;
      } else {
          diff = b.diff(a, 'minutes')
          return `${1440 - diff} minutes left`;
      }
  };
  
  // eslint-disable-next-line no-unused-vars
  const payment_channels = reactive(['raven', 'mint_bank_account', 'flutterwave_checkout', 'raven_virtual_accounts'])
  // eslint-disable-next-line no-unused-vars
  const payment_channel = ref('wema')
  
  const payments = ref(null);
  
  // eslint-disable-next-line no-unused-vars
  const fetchPendingCommitments = async (type) => {
      const loader = $loading.show();
      try {
          const {data} = await axios.post("admin/auth/commitment/pending", {payment_channel: type});
          payments.value = data.payload;
          loader.hide();
      } catch (error) {
          console.error(error);
          loader.hide();
      }
  }
  
  // eslint-disable-next-line no-unused-vars
  const updatePendingCommitmentFee = async (id) =>{
       const loader = $loading.show();
      try {
          const {data} = await axios.post("admin/auth/commitment/pending/confirm", {reference_id : id})
          payments.value = data.payload
          loader.hide()
      } catch (error) {
          console.error(error);
          loader.hide()
      }
  }
  
  // eslint-disable-next-line no-unused-vars
  const deleteCommitmentFee = async (id) =>{
       const loader = $loading.show();
      try {
          const {data} = await axios.post("admin/auth/commitment/pending/delete", {reference_id : id})
          payments.value = data.payload
          loader.hide()
      } catch (error) {
          console.error(error);
          loader.hide()
      }
  }
  
  onMounted(()=>{
      fetchPendingCommitments('wema');
  })
  </script>
  
  <style scoped>
  tr, thead, td{
  padding: 6px 4px;
  }

  .myLeft{
    left: 20%;
  }
  
  </style>