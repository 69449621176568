<template>
  <main>
    <h1 class="font-semibold text-2xl mb-6">Update Payment</h1>
    <div class="m-6 p-4 rounded border">
        <form @submit.prevent="updatePayment" class="">
            <div class="flex flex-col">
                <label for="reference_id" class="font-semibold text-lg">Transaction Reference ID</label>
                <input required type="text" v-model="payload.reference_id" name="reference_id" id="reference_id" class="p-2 rounded-sm border"  />
            </div>
            <div class="flex flex-col my-6">
                <label for="amount" class="font-semibold text-lg">Transaction Amount</label>
                <input required type="number" v-model="payload.amount" name="amount" id="amount" min="0" max="10000" class="p-2 rounded-sm border" />
            </div>
            <button type="submit" class="p-4 bg-emerald-600 text-white">Update Payment</button>
        </form>
    </div>
  </main>
</template>

<script setup>
import { reactive } from "@vue/reactivity";
// import { computed, onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
// import { useRouter } from "vue-router";
// import { useStore } from "vuex";

// const store = useStore();
// const router = useRouter();


const payload = reactive({
    reference_id: '',
    amount:''
});

// const user = computed(()=> {
//     return store.state.auth.user;
// }
// )

// eslint-disable-next-line no-unused-vars
const updatePayment = async () => {
    try {
        await axios.post('admin/auth/payment/update', payload);
        swal({
            title: 'Success',
            text: 'Payment Updated Successfully'
        })
        payload.reference_id = ''
        payload.amount = ''
        return;
    } catch (error) {
        console.error(error);
        swal({
            title: 'Oops!',
            text: error?.response?.data?.payload ?? "Unable to update this transaction. Please try again later."
        })
    }
}

// onMounted(()=> {
//     if (!user?.value?.roles?.includes('superadmin') && !user?.value?.roles?.includes('accounts')) {
//         swal({
//             title: "Warning!!!",
//             text: 'Unauthorized'
//         })
//         return router.push({name: 'AllPayments'});
//     }
// })
</script>

<style>

</style>