<template>
  <div>
    <div v-if="stats?.length === 0" class="font-semibold text-lg text-blueGray-700 mb-4 px-4">Loading ...</div>
    <div class="flex flex-wrap mt-4">
      <div class="space-x-4 py-4">
        <button @click="fetchStats(8)" :class="cohort == 8 ? 'border-2 border-blue-800' : '' " class="bg-blue-400 hover:bg-blue-300 transition ease-linear duration-200 rounded-md px-4 py-2 text-white">
          Cohort 8.0 Data
        </button>
        <button @click="fetchStats(7)" :class="cohort == 7 ? 'border-2 border-blue-800' : '' " class="bg-blue-400 hover:bg-blue-300 transition ease-linear duration-200 rounded-md px-4 py-2 text-white">
          Cohort 7.0 Data
        </button>
        <button @click="fetchStats(6)" :class="cohort == 6 ? 'border-2 border-blue-800' : '' "  class="bg-blue-400 hover:bg-blue-300 transition ease-linear duration-200 rounded-md px-4 py-2 text-white">
          Cohort 6.0 Data
        </button>
      </div>
      <div class="w-full mb-12">
        <line-chart 
          :users="stats.weekly_users" 
          :baselines="stats.weekly_baselines" 
          :ravens="stats.weekly_ravens" />
      </div>
      <div class="w-full px-4 grid md:grid-cols-2 gap-32">
        <div  v-if="stats.users && stats.country_percent.length > 0" class="mb-12 flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Completion Rate</h2>
          <pie-chart :chartVal="completedStats.regRate" class=" mx-auto" />
        </div>
        <div v-if="stats.country_percent && stats.country_percent.length > 0" class="mb-12 flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Nigeria vs Others</h2>
          <pie-chart :chartVal="stats.country_percent" class=" mx-auto" />
        </div>
        <div v-if="stats.countries && stats.countries.length > 0" class="mb-12 flex-col items-center ">
          <h2  class="font-semibold text-lg text-blueGray-700 mb-4">Top 10 Countries</h2>
          <pie-chart :chartVal="stats.countries" class=" mx-auto" />
        </div>
        <div v-if="stats.courses && stats.courses.length > 0" class="mb-12 flex-col items-center ">
          <h2  class="font-semibold text-lg text-blueGray-700 mb-4">Course Distribution</h2>
          <pie-chart :chartVal="stats.courses" class=" mx-auto" />
        </div>
        <div v-if="stats.level && stats.level.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Level of Knowledge</h2>
          <pie-chart :chartVal="stats.level" class=" mx-auto" />
        </div>
        <div v-if="stats.education && stats.education.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Education Qualification</h2>
          <pie-chart :chartVal="stats.education" class=" mx-auto" />
        </div>
        <div v-if="stats.employment && stats.employment.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Employment Status</h2>
          <pie-chart :chartVal="stats.employment" class=" mx-auto" />
        </div>
        <div v-if="stats.earning && stats.earning.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Earning Power</h2>
          <pie-chart :chartVal="stats.earning" class=" mx-auto" />
        </div>
        <div v-if="stats.reason && stats.reason.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Reason for Joining</h2>
          <pie-chart :chartVal="stats.reason" class=" mx-auto" />
        </div>
        <div v-if="stats.availability && stats.availability.length > 0" class="mb-12 flex flex-col items-center ">
          <h2 class="font-semibold text-lg text-blueGray-700 mb-4">Availability</h2>
          <pie-chart :chartVal="stats.availability" class=" mx-auto" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PieChart from "@/components/Cards/PieChart.vue";
import LineChart from "@/components/Cards/LineChart.vue";
import axios from "axios";
import swal from 'sweetalert';

export default {
  data() {
    return {
      stats:[],
      cohort: 8,
    }
  },
  computed:{
    completedStats(){
      return {
        regRate:[
          {
            description: "Completed",
            count: this.stats.users ? ( this.stats.completed / this.stats.users ) * 100 : '',
          },
          {
            description: "Incomplete",
            count: this.stats.users ? ( (this.stats.users - this.stats.completed) / this.stats.users ) * 100 : '',
          },
        ]
      }
    }
  },
  components: {
    PieChart,
    LineChart,
  },
  methods: {
    fetchStats1(url) {
      return axios.get(url);
    },
    fetchStats2(url) {
      return axios.get(url);
    },
    fetchStats3(url) {
      return axios.get(url);
    },
    fetchStats4(url) {
      return axios.get(url);
    },
    fetchStats(cohort){
      this.cohort = cohort;
      let loader = this.$loading.show();
      Promise.all([this.fetchStats1("admin/auth/stats1/"+cohort), this.fetchStats2("admin/auth/stats2/"+cohort), this.fetchStats3("admin/auth/stats3/"+cohort), this.fetchStats4("admin/auth/stats4/"+cohort)])
      .then((results) => {
        const res1 = results[0].data.payload
        const res2 = results[1].data.payload
        const res3 = results[2].data.payload
        const res4 = results[3].data.payload
        const newRes = Object.assign(res1, res2, res3, res4)
        this.stats = newRes;
        loader.hide()
        return;
      })
      .catch(()=> {
        loader.hide();
        swal({
          title: "Oops!",
          text: "Unable to fetch stats data at the moment. Please refresh ..."
        })
      });
    },
    fetchStats_6(){
      let loader = this.$loading.show();
      Promise.all([this.fetchStats1("admin/auth/v6/stats1"), this.fetchStats2("admin/auth/v6/stats2"), this.fetchStats3("admin/auth/v6/stats3"), this.fetchStats4("admin/auth/v6/stats4")])
      .then((results) => {
        const res1 = results[0].data.payload
        const res2 = results[1].data.payload
        const res3 = results[2].data.payload
        const res4 = results[3].data.payload
        const newRes = Object.assign(res1, res2, res3, res4)
        this.stats = newRes;
        loader.hide()
        return;
      })
      .catch(()=> {
        loader.hide();
        swal({
          title: "Oops!",
          text: "Unable to fetch stats data at the moment. Please refresh ..."
        })
      });
    }
  },
  mounted() {
    this.fetchStats(this.cohort);
  },
};
</script>
