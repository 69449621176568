<template>
  <main>
    <h1 class="font-semibold text-3xl mb-6 underline underline-offset-2">All Start Up Bootcamp Teams</h1>
    <div class="grid grid-cols-3 gap-8">
        <template v-if="teams?.length > 0">
          <div class="border rounded-md p-4 space-y-4 bg-gray-200 shadow-2xl cursor-pointer" v-for="team in teams" :key="team.id">
              <h2 class="font-semibold text-2xl">{{team.title}}</h2>
              <p class="text-sm">{{team.description}}</p>
              <div class="flex justify-between items-start">
                <button @click="editTeam(team.id)" class="text-blue-500 font-medium">Edit <i class="far fa-edit"></i></button>
                <button @click="deleteTeam(team.id, team.title)" class="text-red-500 font-medium">Delete <i class="fas fa-trash-alt"></i></button>
              </div>
          </div>
        </template>
        <template v-if="teams?.length === 0">
          <h1>There are currently no teams available...</h1>
        </template>
        <template v-if="!teams">
          <h1>Fetching teams...</h1>
        </template>
    </div>
    <div v-if="teams?.length > 0" class="my-6">
      Click <strong @click="downloadData()" class="text-blue-400 cursor-pointer">here to download data</strong>
    </div>
  </main>
</template>

<script setup>
import { useRouter } from "vue-router";
import { inject, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
const $loading = inject("$loading");

const router = useRouter();
const teams = ref(null);

const getTeams = async () => {
  const loader = $loading.show();
  try {
      const { data } = await axios.get("admin/auth/teams")
      teams.value = data.payload;
      loader.hide();
  } catch (error) {
    console.error(error);
    loader.hide();
    teams.value = [];
  }
}
// eslint-disable-next-line no-unused-vars
const deleteTeam = async (id, title) => {
  swal({
    title: "Warning",
    text: `Are you sure you want to delete team ${title}`,
    buttons: true,
  }).then(async (result) => {
    if (result) {
      const loader = $loading.show();
      try {
          await axios.delete(`admin/auth/teams/${id}`)
          await getTeams();
          loader.hide();
      } catch (error) {
        console.error(error);
        loader.hide();
      }
    }
  })
}
// eslint-disable-next-line no-unused-vars
const downloadData = async () => {
      const loader = $loading.show();
      try {
          const {data} = await axios.post('admin/auth/teams');
          console.log(data.payload)
          convertToCSV(data.payload, 'Bootcamp Data')
          loader.hide();
      } catch (error) {
        console.error(error);
        loader.hide();
      }
}

// eslint-disable-next-line no-unused-vars
const convertToCSV = (arr, filename) => {
    const array = [Object.keys(arr[0])].concat(arr)
    const result =  array.map(it => {
        return Object.values(it).toString()
    }).join('\n')
    const TIME_STAMP = new Date().toISOString();
    const _filename = `${filename??''}${TIME_STAMP}`;
    handleDownload(result, _filename)
};

const handleDownload = (file, filename) => {
    const newBlob = new Blob([file], { type: 'application/octet-stream' })
    let url = URL.createObjectURL(newBlob)
    var a = document.createElement("a")
    a.href =  url;
    a.download = filename + '.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0);
}

// eslint-disable-next-line no-unused-vars
const editTeam = (id) => {
  return router.push({name: "EditBootcampTeam", params:{id}});
} 

onMounted(()=> {
  getTeams();
})
</script>

<style>

</style>