<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-semiold text-2xl mb-4">Available Airdrops</h1>
    <table v-if="airdrops?.length > 0" class="w-full table-auto border-collapse border-2 border-slate-500 px-4">
        <tr>
            <th>Title</th>
            <th>Count</th>
            <th>Week</th>
            <th>Batch</th>
            <th>Available On</th>
            <th>Action</th>
        </tr>
        <tr v-for="(airdrop, index) in airdrops" :key="index">
            <td>{{airdrop.title}}</td>
            <td>{{airdrop.count}}</td>
            <td>{{airdrop.week}}</td>
            <td>{{airdrop.batch_id}}</td>
            <td>{{airdrop.available_on}}</td>
            <td>
                <button @click="deleteAirdrop(airdrop.id, airdrop.available_on)" class="rounded cursor-pointer mb-4 text-white font-semibold bg-red-400 px-4 py-2">
                    Delete
                </button>
            </td>
        </tr>
    </table>
    <h2 v-if="!airdrops">
        Loading ...
    </h2>
    <h2 v-if="airdrops?.length === 0">
        Data not available
    </h2>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";

const airdrops = ref(null);
const isLoading = ref(false);

const fetchAirdrops = async () => {
    try {
        const { data } = await axios.get("admin/auth/airdrops/available")
        airdrops.value = data.payload
    } catch (error) {
        airdrops.value = []
        console.log(error)
    }
};

const nowTime = new Date();

// eslint-disable-next-line no-unused-vars
const deleteAirdrop = async (id, time) => {
    // return console.log(time, new Date(time))

    // eslint-disable-next-line no-unreachable
    if (nowTime > new Date(time)) {
        swal({
            title: "Oops!!",
            text: "You can no longer delete this airdrop as it is already available"
        })
        return;
    }
    isLoading.value = true;
    try {
        const { data } = await axios.post("admin/auth/airdrops/delete", {id:id})
        airdrops.value = data.payload
        swal({
            title: "Success",
            text: "Airdrop deleted"
        })
        isLoading.value = false;
    } catch (error) {
        swal({
            title: "Oops!",
            text: "Unable to delete airdrop. Please try again."
        })
        isLoading.value = false;
        console.log(error)
    }
}

onMounted(() => {
    console.log(airdrops.value)
    fetchAirdrops();
})
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
text-align: center;
}

table {margin: 12px 0;}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>