<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <div class="container py-12">
      <BackButton />
      <div class="flex items-center justify-between">
        <div>
          <h1 class="font-header-1 font-bold text-3xl md:text-4xl text-black">
            Task
          </h1>
          <p class="font-medium mt-2 text-body-text">Delete Task</p>
        </div>
        <!-- <button
          class="bg-[#000] text-white p-3 rounded-lg"
          @click="$router.push({ name: 'UploadPage' })"
        >
          View Upload
        </button> -->
      </div>
      <form
        @submit.prevent="handleSubmit"
        class="grid gap-4 grid-flow-row my-8 border rounded-md p-4"
      >
        <div class="flex flex-col space-y-2">
          <label for="title">Task</label>
          <input
            type="text"
            id="task"
            rows="5"
            class="border border-blue-600 bottom-1 rounded-lg px-3 pt-3"
            placeholder="Enter Task id"
            v-model="id"
            required
          />
        </div>
        <button type="submit" class="bg-red-600">Delete</button>
      </form>
      <!-- <vue-progress-bar></vue-progress-bar> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

// import RadialProgressBar from "vue3-radial-progress";
// import { createToast } from "mosha-vue-toastify";
export default {
  data() {
    return {
      id: "",
    };
  },

  methods: {
    reset() {
      this.id = "";
    },

    async handleSubmit() {
      let loader = this.$loading.show();

      try {
        const res = await axios.post("tasks/delete/" + this.id);
        if (res.status) {
          swal("Task deleted succesfully", "", "success");
          this.reset();
          // createToast("Task created succesfully", { type: "success" });
          loader.hide();
        }
      } catch (error) {
        // createToast("Sorry an error occured", { type: "danger" });
        this.reset();
        swal("Oops!", "Something went wrong!", "error");
        console.log(error);
        loader.hide();
      }
    },
  },
};
</script>

<style scoped>
button {
  /* @apply bg-red-600; */
  background-color: #cd1414 !important;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}
#task {
  padding: 10px !important;
}

#upload.active {
  display: block;
}

/* input,
select {
  @apply bg-gray-100 text-body-text border rounded-md p-20;
}

label {
  @apply font-semibold text-body-text;
} */
</style>
