<template>
  <main>
    <div class="my-24 container">
            <DataTable
                :columns="columns"
                :data="applications"
                class="display w-full text-sm truncate"
                style="width:100%"
            >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Team</th>
                        <th>Team Type</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Team</th>
                        <th>Team Type</th>
                        <th>Status</th>
                    </tr>
                </tfoot>
            </DataTable>
        </div>
  </main>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net-vue3';
import axios from 'axios';
import { inject, onMounted, ref } from 'vue';


const $loading = inject("$loading");

const applications = ref(null);
// eslint-disable-next-line no-unused-vars
const columns  = [
    { data: 'user.full_name' },
    { data: 'user.email' },
    { data: 'user.phone_number' },
    { data: 'user.gender' },
    { data: 'bootcamp.title' },
    { data: 'bootcamp.type' },
    { data: 'status' }
]
// eslint-disable-next-line no-unused-vars
const options = {
  dom: 'Bftip',
  responsive: true,
  select: true,
};

const  fetchApplications = async () => {
    let loader = $loading.show()
    try {
        const { data } = await axios.get('v2/admin/auth/bootcamp/team-lead/applications')
        applications.value = data.data;
        loader.hide()
    } catch (error) {
        loader.hide()
        console.error(error);
    }
}
 onMounted(() => {
    fetchApplications();
 })
</script>

<style>
</style>