import { createRouter, createWebHistory } from "vue-router";


// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import CohortEight from "@/views/admin/CohortEight.vue";
import Charts from "@/views/admin/Charts.vue";
import Users from "@/views/admin/Users.vue";
import Referrals from "@/views/admin/Referrals.vue";
import AddLessons from "@/views/admin/AddLessons.vue";
import ExportData from "@/views/admin/ExportData.vue";
import SpinWinners from "@/views/admin/SpinWinners.vue";
import GoodiesWinners from "@/views/admin/GoodiesWinners.vue";
import QuizStatsPage from "@/views/admin/QuizStatsPage.vue";
import LessonStatsPage from "@/views/admin/LessonStatsPage.vue";
import AnnouncementsPage from "@/views/admin/info/AnnouncementsPage.vue";
import SendNotificationsPage from "@/views/admin/info/SendNotificationsPage.vue";
import UpdateSiteSettings from "@/views/admin/settings/UpdateSiteSettings.vue";
import WebAppStatsPage from "@/views/admin/settings/WebAppStatsPage.vue";

//
import AddAirdrops from "@/views/admin/goodies/AddAirdrops.vue";
import AddChallenge from "@/views/admin/goodies/AddChallenge.vue";
import AddChallengeWinner from "@/views/admin/goodies/AddChallengeWinner.vue";
import AvailableAirdrops from "@/views/admin/goodies/AvailableAirdrops.vue";
import AllChallenges from "@/views/admin/goodies/AllChallenges.vue";
import ChallengeWinners from "@/views/admin/goodies/ChallengeWinners.vue";


//
import UserActivityPage from "@/views/admin/UserActivityPage.vue";

// Update Vimeo Links 
import UpdateVimeoLinks from "@/views/admin/lessons/UpdateVimeoLinks.vue";
import SelectTrackWeek from "@/views/admin/lessons/SelectTrackWeek.vue";


// Payment routes
import PaymentStats from "@/views/admin/payment/PaymentStats.vue";
import AllPayments from "@/views/admin/payment/AllPayments.vue";

import CreatePaymentPage from "@/views/admin/payment/CreatePaymentPage.vue";
import UpdatePaymentPage from "@/views/admin/payment/UpdatePaymentPage.vue";
import PendingPaymentsPage from "@/views/admin/payment/PendingPaymentsPage.vue";
import PendingCommitmentPage from "@/views/admin/payment/PendingCommitmentPage.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";


// referral challenger routes 
import ReferralWinnersPage from "@/views/admin/goodies/ReferralWinnersPage.vue";



// Bootcamp ROutes 
import CreateTeam from "@/views/admin/bootcamp/CreateTeam.vue";
import StartUpTeams from "@/views/admin/bootcamp/StartUpTeams.vue";
import EditBootcampTeam from "@/views/admin/bootcamp/EditBootcampTeam.vue";
import BootcampWaitlist from "@/views/admin/bootcamp/BootcampWaitlist.vue";
import BootcampSettings from "@/views/admin/bootcamp/BootcampSettings.vue";
import BootcampTransactions from "@/views/admin/bootcamp/BootcampTransactions.vue";
import BootcampDates from "@/views/admin/bootcamp/BootcampDates.vue";
import ReviewTeamLead from "@/views/admin/bootcamp/ReviewTeamLead.vue";
import BootcampTransactionsConfirmation from "@/views/admin/bootcamp/BootcampTransactionsConfirmation.vue";


//
// routes

// create new user 
import CreateUserProfile from "@/views/admin/settings/CreateUserProfile.vue";

import FeedbackStats from "@/views/admin/reviews/FeedbackStats.vue";


import AddGamePage from "@/views/admin/game/AddGamePage.vue";
import AddTasks from "@/views/admin/tasks/AddTasks.vue";
import ViewTask from "@/views/admin/tasks/ViewTask.vue";
import DeleteTask from "@/views/admin/tasks/DeleteTask.vue";
import ViewAllGamesPage from "@/views/admin/game/ViewAllGamesPage.vue";
import AllGame from "@/views/admin/game/AllGame.vue";


import ReferralEarnings from "@/views/admin/referrals/ReferralEarnings.vue";
import MarketingData from "@/views/admin/MarketingData.vue";
const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        name: "Dashboard"
      },
      {
        path: "/admin/new",
        component: CohortEight,
        name: "CohortEight"
      },
      {
        path: "/admin/charts",
        component: Charts,
        name: "Charts",
      },
      {
        path: "/admin/users",
        component: Users,
        name: "Users",
      },
      {
        path: "/admin/referrals",
        component: Referrals,
        name: "Referrals",
      },
      {
        path: "/admin/dev/lesson",
        component: AddLessons,
        name: "AddLessons",
      },
      {
        path: "/admin/data/export",
        component: ExportData,
        name: "ExportData",
      },
      {
        path: "/admin/winners/spin",
        component: SpinWinners,
        name: "SpinWinners",
      },
      {
        path: "/admin/winners/goodies",
        component: GoodiesWinners,
        name: "GoodiesWinners",
      },
      {
        path: "/admin/quiz/stats",
        component: QuizStatsPage,
        name: "QuizStatsPage",
      },
      {
        path: "/admin/lessons/stats",
        component: LessonStatsPage,
        name: "LessonStatsPage",
      },
      {
        path: "/admin/notify",
        component: SendNotificationsPage,
        name: "SendNotificationsPage",
      },
      {
        path: "/admin/announce",
        component: AnnouncementsPage,
        name: "AnnouncementsPage",
      },
      {
        path: "/admin/settings",
        component: UpdateSiteSettings,
        name: "UpdateSiteSettings",
      },
      {
        path: "/admin/create-user",
        component: CreateUserProfile,
        name: "CreateUserProfile",
      },
      {
        path: "/admin/goodies/add/airdrop",
        component: AddAirdrops,
        name: "AddAirdrops",
      },
      {
        path: "/admin/goodies/add/challenge",
        component: AddChallenge,
        name: "AddChallenge",
      },
      {
        path: "/admin/goodies/add/challenge/winner",
        component: AddChallengeWinner,
        name: "AddChallengeWinner",
      },
      {
        path: "/admin/goodies/airdrops/all",
        component: AvailableAirdrops,
        name: "AvailableAirdrops",
      },
      {
        path: "/admin/goodies/challenge/all",
        component: AllChallenges,
        name: "AllChallenges",
      },
      {
        path: "/admin/goodies/challenge/winners",
        component: ChallengeWinners,
        name: "ChallengeWinners",
      },
      {
        path: "/admin/users/activity",
        component: UserActivityPage,
        name: "UserActivityPage",
      },
      {
        path: "/admin/lessons/update",
        component: UpdateVimeoLinks,
        name: "UpdateVimeoLinks",
      },
      {
        path: "/admin/lessons/track",
        component: SelectTrackWeek,
        name: "SelectTrackWeek",
      },
      // Payment routes
      {
        path: "/admin/payment/stats",
        component: PaymentStats,
        name: "PaymentStats",
      },
      {
        path: "/admin/payment/pending",
        component: PendingPaymentsPage,
        name: "PendingPaymentsPage",
      },
      {
        path: "/admin/commitment/pending",
        component: PendingCommitmentPage,
        name: "PendingCommitmentPage",
      },
      {
        path: "/admin/payment/all",
        component: AllPayments,
        name: "AllPayments",
      },
      {
        path: "/admin/payment/add",
        component: CreatePaymentPage,
        name: "CreatePaymentPage",
      },
      {
        path: "/admin/payment/add",
        component: UpdatePaymentPage,
        name: "UpdatePaymentPage",
      },
      //

      {
        path: "/admin/referrals/winners",
        component: ReferralWinnersPage,
        name: "ReferralWinnersPage",
      },
      {
        path: "/admin/bootcamp/create",
        component: CreateTeam,
        name: "CreateTeam",
      },
      {
        path: "/admin/bootcamp/all",
        component: StartUpTeams,
        name: "StartUpTeams",
      },
      {
        path: "/admin/bootcamp/settings",
        component: BootcampSettings,
        name: "BootcampSettings",
      },
      {
        path: "/admin/bootcamp/transactions/all",
        component: BootcampTransactions,
        name: "BootcampTransactions",
      },
      {
        path: "/admin/bootcamp/dates",
        component: BootcampDates,
        name: "BootcampDates",
      },
      {
        path: "/admin/bootcamp/team-leads",
        component: ReviewTeamLead,
        name: "ReviewTeamLead",
      },
      {
        path: "/admin/bootcamp/transactions/update",
        component: BootcampTransactionsConfirmation,
        name: "BootcampTransactionsConfirmation",
      },
      {
        path: "/admin/bootcamp/edit",
        component: EditBootcampTeam,
        name: "EditBootcampTeam",
      },
      {
        path: "/admin/bootcamp/waitlist",
        component: BootcampWaitlist,
        name: "BootcampWaitlist",
      },
      {
        path: "/admin/reviews/all",
        component: FeedbackStats,
        name: "FeedbackStats",
      },
      {
        path: "/admin/app/stats",
        component: WebAppStatsPage,
        name: "WebAppStatsPage",
      },
      {
        path: "/admin/games/add",
        component: AddGamePage,
        name: "AddGamePage",
      },
      {
        path: "/admin/task/add",
        component: AddTasks,
        name: "AddTasks"
      },
      {
        path: "admin/task/view",
        component: ViewTask,
        name: "ViewTask"
      },
      {
        path: "/admin/task/delete",
        component: DeleteTask,
        name: "DeleteTask"
      },
      {
        path: "/admin/games/all/:id",
        component: ViewAllGamesPage,
        name: "ViewAllGamesPage",
      },
      {
        path: "/admin/games/main",
        component: AllGame,
        name: "AllGame",
      },
      {
        path: "/admin/referrals",
        component: ReferralEarnings,
        name: "ReferralEarnings",
      },
      {
        path: "/admin/marketing/data",
        component: MarketingData,
        name: "MarketingData",
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
        name: "Login",
      },
    ],
  },

  {
    path: "/",
    redirect: "/auth"
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
