<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10">
            <form @submit.prevent="login">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  v-model="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  v-model="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>

              <div class="text-center mt-6">
                <button :disabled="isLoading"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
            <div class=" text-red-600 text-2xl animate-pulse font-semibold text-center">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
        user: "auth/user",
    }),
  },
  methods:{
    ...mapActions({
        attempt: "auth/attempt",
    }),
    login() {
      try {
          this.isLoading = true;
          this.errorMessage = "";
          const data = {
              email: this.email,
              password: this.password,
          };
          axios
              .post("admin/login", data)
              .then((response) => {
                  this.attempt(response.data.payload.token)
                  .then(() => {
                    this.$router.push({ name: "Dashboard" })
                  })
              })
              .catch((err) => {
                  console.log(err)
                  this.errorMessage = "Incorrect Details"
                  this.isLoading = false;
              });
      } catch (error) {
        console.log(error)
        this.errorMessage = "Incorrect Details"
        this.isLoading = false;
      }
    }
  },
  mounted(){
    if (this.user) {
      this.$router.push({ name: "Dashboard" })
    }
  }
};
</script>
