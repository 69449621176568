<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">
        Add Challenge Winner
    </h1>
    <form @submit.prevent="addChallengeWinner()" class="my-4 space-y-4 grid grid-flow-row gap-4 md:w-3/5">
        <label for="title" class="font-semibold mb-2" v-if="false">
            Intern Email:
        </label>
        <input required type="email" placeholder="Ex.: adedoyin@sidehutle.ng" name="email" id="email" v-model="payload.email" class="mb-2" />
        <label for="description" class="font-semibold mb-2">
            Challenge Prize:
        </label>
        <input required type="text" placeholder="Enter prize to be given to the winner..." name="prize" id="prize" v-model="payload.prize" class="mb-2" />
        <label for="challenge" class="font-semibold mb-2">
            Challenge:
        </label>
        <select required name="challenge" id="challenge" v-model="payload.challenge_id">
            <option :value="challenge.id" v-for="challenge in challenges" :key="challenge.id">
                {{ challenge.description }}
            </option>
        </select>

        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4">Add Winner</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";

const isLoading = ref(false);
// eslint-disable-next-line
const payload = ref({
    prize: "",
    email: "",
    challenge_id: "",
});
const challenges = ref(null);
// eslint-disable-next-line
const addChallengeWinner = async () => {
    try {
        isLoading.value = true;
        await axios.post("admin/auth/challenge/winner", payload.value);
        swal({
            title: "Success!",
            text: "Winner has been saved successfully"
        })
        payload.value.email = "",
        payload.value.challenge_id = "",
        payload.value.prize = "",
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        swal({
            title: "Oops!",
            text: error?.response?.data?.payload ?? "Unable to save winner. Please try again later."
        })
    }
}

onMounted(async () => {
  try {
        const { data } = await axios.get("admin/auth/challenge/available")
        challenges.value = data.payload
    } catch (error) {
        challenges.value = []
        console.log(error)
    }
})
</script>

<style>

</style>