<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-semibold text-xl my-4">Payment Data</h1>

    <h1 class="font-semibold text-lg my-4">Confirmed Payments</h1>
    <table class="w-full mb-12 px-4 mt-4" v-if="payments?.confirmed?.length !== 0">
        <tr class="text-left">
            <th>Item</th>
            <th>Count</th>
        </tr>
        <tr v-for="(payment, index ) in payments.confirmed" :key="index">
            <td>
                {{payment.title }}
            </td>
            <td>
                {{ payment.count }}
            </td>
        </tr>
        <tr class="border-t-2 py-4 font-semibold">
            <td>
                Sum Total
            </td>
            <td>
                {{sumCountConfirmed}}
            </td>
        </tr>
    </table>
    <div v-if="payments?.confirmed?.length === 0" class="my-4">
        No Data Available
    </div>
    <h1 class="font-semibold text-lg my-4">Pending Payments</h1>
    <table class="w-full mb-12 px-4 mt-4" v-if="payments?.pending?.length !== 0">
        <tr class="text-left">
            <th>Item</th>
            <th>Count</th>
        </tr>
        <tr v-for="(payment, index ) in payments.pending" :key="index">
            <td>
                {{ payment.title }}
            </td>
            <td>
                {{ payment.count }}
            </td>
        </tr>
        <tr class="border-t-2 py-4 font-semibold">
            <td>
                Sum Total
            </td>
            <td>
                {{sumCountPending}}
            </td>
        </tr>
    </table>
    <div v-if="payments?.pending?.length === 0" class="my-4">
        No Data Available
    </div>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import axios from "axios";

const payments = ref([]);
const isLoading = ref(false);

// sum count
// eslint-disable-next-line no-unused-vars
const sumCountConfirmed = computed(() => {
    if (payments?.value?.confirmed?.length > 0) {
        const totalCount = payments.value.confirmed.reduce((accum,item) => accum + item.count, 0)
        return totalCount;
    } else {
        return 0;
    }
});
// sum count
// eslint-disable-next-line no-unused-vars
const sumCountPending = computed(() => {
    if (payments?.value?.pending?.length > 0) {
        const totalCount = payments.value.pending.reduce((accum,item) => accum + item.count, 0)
        return totalCount;
    } else {
        return 0;
    }
});

const fetchPayments = async () => {
    isLoading.value = true;
    try {
        const { data } = await axios.get('admin/auth/payment/stats');
        payments.value = data.payload;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
        isLoading.value = false;
    }
}

onMounted(() => {
    fetchPayments();
})
</script>

<style>

</style>