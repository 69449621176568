<template>
  <div class=" min-w-full"> 
      <vue3-chart-js v-bind="lineChart" v-if="lineChart.data.labels.length >= 1" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
export default {
  components: {
    Vue3ChartJs,
  },

  data() {
    return {
        lineChart: {
            type: "line",
            data: {
                labels: [],
                datasets: [],
            },
        }
    }
  },
  watch:{
    users(){
      this.sortData()
    }
  },
  methods:{
    sortData(){
      let labels = [];
      for (let i = 0; i < this.users.length; i++) {
        labels.push(`Week ${i+1}`);
      }
      let users = {
        data:[],
        label: "Users",
        fill: false,
        borderColor: "green",
        tension: 0.5,
        backgroundColor: "green",
      };
      this.users.forEach(element => {
        users.data.push(element.count)
      });
      let baselines = {
        data:[],
        label: "Baselines",
        fill: false,
        borderColor: "blue",
        tension: 0.5,
        backgroundColor: "blue",
      };
      this.baselines.forEach(element => {
        baselines.data.push(element.count)
      });
      let ravens = {
        data:[],
        label: "Ravens",
        fill: false,
        borderColor: "red",
        tension: 0.5,
        backgroundColor: "red",
      };
      this.ravens.forEach(element => {
        ravens.data.push(element.count)
      });
      this.lineChart.data.labels = labels;
      this.lineChart.data.datasets = [users, baselines, ravens]
    }
  },
  props:{
    users : {
        type: Array,
        default(){
            return []
        },
    },
    baselines : {
        type: Array,
        default(){
            return []
        },
    },
    ravens : {
        type: Array,
        default(){
            return []
        },
    }
  }
};
</script>
