<template>
  <main>
    <h1 class="font-semiold text-2xl mb-4">Winners for Spin The Wheel</h1>
    <table v-if="winners?.length > 0" class="w-full table-auto border-collapse border-2 border-slate-500 px-4">
        <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Prize</th>
            <th>Date</th>
        </tr>
        <tr v-for="(winner, index) in winners" :key="index">
            <td>{{`${winner.first_name} ${winner.last_name}`}}</td>
            <td>{{winner.email}}</td>
            <td>{{winner.phone_number}}</td>
            <td>{{winner.value}}</td>
            <td>{{winner.created_at}}</td>
        </tr>
    </table>
    <h2 v-if="!winners">
        Loading ...
    </h2>
    <h2 v-if="winners?.length === 0">
        Data not available
    </h2>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";

const winners = ref(null);

const fetchWinners = async () => {
    try {
        const { data } = await axios.get("admin/auth/winners/spin")
        winners.value = data.payload
    } catch (error) {
        winners.value = []
        console.log(error)
    }
}

onMounted(() => {
    fetchWinners();
})
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>