<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="text-3xl font-bold">Watched Lessons Stats Data</h1>
    <h2 v-if="!stats" class="text-lg" style="margin-top:12px">Fetching Your data...</h2>
    <template v-if="stats">
        <details v-if="stats?.batch_1" class="my-4">
            <summary class="text-2xl font-bold">Batch 1</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Watched Lessons Per Week</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_1?.watched?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_1?.watched" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <details v-if="stats?.batch_2" class="my-4">
            <summary class="text-2xl font-bold">Batch 2</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Watched Lessons Per Week</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_2?.watched?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_2?.watched" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <details v-if="stats?.batch_3" class="my-4">
            <summary class="text-2xl font-bold">Batch 3</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Watched Lessons Per Week</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_3?.watched?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_3?.watched" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <h2 v-else class="my-4 py-4">No Data Available</h2>
    </template>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
const stats = ref(null)
const isLoading = ref(false);

const fetchStats = async () => {
    try {
        isLoading.value = true;
        const { data } = await axios.get("admin/auth/learning/lessons")
        stats.value = data.payload
        isLoading.value = false;
    } catch (error) {
        swal({
            title: "Error",
            text: error?.response?.data?.payload ?? "Please Try Again Later",
        })
        isLoading.value = false;
        stats.value = []
    }
};
onMounted(() => {
    fetchStats();
})
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
text-align: center;
}

table {margin: 12px 0;}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>