<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">
        Add Challenge
    </h1>
    <form @submit.prevent="addChallenge()" class="my-4 space-y-4 grid grid-flow-row gap-4 md:w-3/5">
        <label for="title" class="font-semibold mb-2" v-if="false">
            Challenge Title:
        </label>
        <input required type="text" placeholder="Ex.: 2GB Data" name="title" id="title" v-model="payload.title" class="mb-2" v-if="false" />
        <label for="description" class="font-semibold mb-2">
            Challenge Description:
        </label>
        <input required type="text" placeholder="Enter Challenge description here..." name="description" id="description" v-model="payload.description" class="mb-2" />
        <label for="week" class="font-semibold mb-2">
            Week:
        </label>
        <select required name="week" id="week" v-model="payload.week">
            <option :value="week" v-for="week in [1,2,3,4]" :key="week">
                {{ `Week ${week}` }}
            </option>
        </select>
        <label for="week" class="font-semibold mb-2">
            Batch:
        </label>
        <select required name="batch_id" id="batch_id" v-model="payload.batch_id">
            <option :value="batch_id" v-for="batch_id in [1,2,3,4,5]" :key="batch_id">
                {{ `Batch ${batch_id}` }}
            </option>
        </select>
        <label for="available_on" class="font-semibold mb-2">
            Available On:
        </label>
        <input required type="datetime-local" name="available_on" id="available_on" v-model="payload.available_on" />

        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4">Add Challenge</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import axios from "axios";
import swal from "sweetalert";

const isLoading = ref(false);
// eslint-disable-next-line
const payload = ref({
    // title: "",
    description: "",
    week: "",
    batch_id: "",
    available_on: "",
});

// eslint-disable-next-line
const addChallenge = async () => {
    try {
        isLoading.value = true;
        await axios.post("admin/auth/add/challenge", payload.value);
        swal({
            title: "Success!",
            text: "Challenge has been added successfully"
        })
        payload.value.title = "",
        payload.value.description = "",
        payload.value.week = "",
        payload.value.batch_id = "",
        payload.value.available_on = "",
        payload.value.count = ""
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        swal({
            title: "Oops!",
            text: "Unable to add challenge. Please try again later."
        })
    }
}
</script>

<style>

</style>