<template>
  <div>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <form @submit.prevent="saveLesson">
        <fieldset>
            <div class="grid grid-flow-row gap-y-2">
                <h2 class="font-bold text-xl mb-4">Lesson Details:</h2>
                <label for="lesson_title">Lesson Title</label>
                <input required class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" id="lesson_title" type="text" v-model="payload.lesson.title" placeholder="Lesson Title">
                <label for="lesson_description">Lesson Description</label>
                <input required class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" id="lesson_description" type="text" v-model="payload.lesson.description" placeholder="Lesson Description">
                <label for="lesson_module">Lesson Module</label>
                <input required class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" id="lesson_module" type="number" v-model="payload.lesson.module" placeholder="Lesson Module">
                <label for="course_id">Course</label>
                <select class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" name="course_id" id="course_id" v-model="payload.lesson.course_id">
                    <option selected>Select Course</option>
                    <option v-for="course in courses" :key="course.id" :value="course.id">{{course.title}}</option>
                </select>
                <label for="Week">Week</label>
                <select required class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" name="week" id="week" v-model="payload.lesson.week">
                    <option selected value="1">Week 1</option>
                    <option value="2">Week 2</option>
                    <option value="3">Week 3</option>
                    <option value="4">Week 4</option>
                </select>
            </div>
        </fieldset>
        <fieldset>
            <h2 class="font-bold text-xl mb-4">Resource Details:</h2>
            <div v-for="(module, index) in payload.modules" :key="index" class="mb-12 border border-green-400 p-8">
                <div class="grid grid-flow-row">
                    <label for="url">Digital Ocean Link</label>
                    <input required class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" type="url" name="url" id="url" v-model="module.url">
                    <label for="vimeo_url">Vimeo Link</label>
                    <input class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0" type="url" name="vimeo_url" id="vimeo_url" v-model="module.vimeo_url">
                    <h4>Resource Type (Video/Audio)</h4>
                    <div class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0"  @click="selectOption(module.sub_ind, 'video')">
                        <input required class="mr-4" type="radio" :name='index'  id="video" value="video">
                        <label for="video">Video</label>
                    </div>
                    <div class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0"  @click="selectOption(module.sub_ind, 'doc')">
                        <input required class="mr-4" type="radio" :name='index'  id="doc" value="doc">
                        <label for="doc">Document</label>
                    </div>
                    <div class="border rounded-md p-2 my-2 focus:ring-0 focus:outline ring-0"  @click="selectOption(module.sub_ind, 'audio')">
                        <input required class="mr-4" type="radio" :name='index'  id="audio" value="audio">
                        <label for="audio">Audio</label>
                    </div>
                </div>
                <div class="grid grid-flow-row gap-4 mt-2">
                    <span class="bg-red-400 py-2 px-4 mr-12 cursor-pointer rounded-md font-medium text-white w-fit place-self-start"
                        v-if="index == (payload.modules.length - 1)"
                        @click="addField(index,module, payload.modules)"
                    >
                        Add another Resource
                    </span>
                    <span class="bg-red-400 py-2 px-4 mt-4 cursor-pointer rounded-md font-medium text-white w-fit place-self-start"
                        v-if="index > 0"
                        @click="removeField(index, payload.modules) "
                    >
                        Remove resource
                    </span>
                </div>
            </div>
        </fieldset>

        <button v-html="buttonText" type="submit" class="bg-red-400 rounded w-full p-8 py-4 font-semibold text-white text-center"></button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
    data() {
        return {
            isLoading: false,
            formatTypes:[
                {
                    id: 1,
                    value: 'doc',
                    name: 'Document',
                },
                {
                    id: 2,
                    value: 'video',
                    name: 'Video',
                },
                {
                    id: 3,
                    value: 'audio',
                    name: 'Audio',
                },
            ],
            courses:[],
            buttonText: 'Save Lesson Details',
            payload: {
                lesson:{
                    title: '',
                    description: '',
                    module: '',
                    course_id:'',
                    week:''
                },
                modules:[{
                    sub_ind: 0,
                    url: '',
                    vimeo_url:'',
                    format: ''
                }]
            }
        }
    },
    methods:{
        selectOption(id, format) {
            let module = this.payload.modules.find((item) => item.sub_ind === id);
            if (module) {
                module.format = format;
            }
            return;
        },
        addField(index,value, fieldType) {
            fieldType.push(
                {
                    sub_ind: index + 1,
                    url: '',
                    vimeo_url:'',
                    format: ''
                }
            );
        },
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },
        saveLesson(){
            try {
                this.isLoading = true;
                this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
                axios.post("auth/lessons/store", this.payload)
                .then((res) => {
                    this.payload = {
                        lesson:{
                            title: '',
                            description: '',
                            course_id:'',
                            week:''
                        },
                        modules:[{
                            url: '',
                            vimeo_url:'',
                            format: ''
                        }]
                    }
                    this.isLoading = false;
                    console.log(res)
                    swal({
                        title:"Success",
                        text: "Lesson added successfully"
                    })
                    this.buttonText = 'Save Lesson Details'
                })
                .catch((err) => {
                    this.buttonText = 'Save Lesson Details'
                    this.isLoading = false;
                    console.log(err);
                    swal({
                        title:"Error!",
                        text: "Please try again."
                    })
                })
            } catch (error) {
                this.buttonText = 'Save Lesson Details'
                this.isLoading = false;
                console.log(error)
                swal({
                    title:"Error!",
                    text: "Please try again."
                })
            }
        },
        fetchCourses(){
            try {
                axios.get("/courses")
                .then((res) => {
                    this.courses = res.data.payload
                })
                .catch((err) => {
                    console.log(err);
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted(){
        this.fetchCourses();
    }

}
</script>

<style scoped>
label {
    font-weight: 500;
}
</style>