<template>
  <div>
    <div v-if="stats.length === 0" class="font-semibold text-lg text-blueGray-700 mb-4 px-4">Loading ...</div>
    <div class="flex flex-wrap mt-4" v-else>
      <div class="w-full xl:w-11/12 mb-12 xl:mb-0 px-4">
      <div class="space-x-4 py-4">
        <select name="cohort" id="cohort" v-model="cohort">
          <option value="6">Cohort 6.0 Data</option>
          <option value="7">Cohort 7.0 Data</option>
          <option value="8">Cohort 8.0 Data</option>
          <option value="9" selected>Cohort 9.0 Data</option>
          <option value="10">Cohort 10.0 Data</option>
        </select>
      </div>
        <card-page-visits :stats="stats" />
        <template v-if="true">
          <card-table :columns="$options.columns.hear_about_us" :rows="stats.hear_about_us" title="Hear About Us" v-if="stats.hear_about_us" />
          <card-table :columns="$options.columns.availability" :rows="stats.availability" title="Availability" v-if="stats.availability" />
          <card-table :columns="$options.columns.level" :rows="stats.level" title="Level of Education" v-if="stats.level" />
          <card-table :columns="$options.columns.reason" :rows="stats.reason" title="Reason for Joining" v-if="stats.reason" />
          <card-table :columns="$options.columns.earning" :rows="stats.earning" title="Monthly Earning" v-if="stats.earning" />
          <card-table :columns="$options.columns.employment" :rows="stats.employment" title="Employment Status" v-if="stats.employment" />
          <card-table :columns="$options.columns.education" :rows="stats.education" title="Education Level" v-if="stats.education" />
          <card-table :columns="$options.columns.courses" :rows="stats.courses" title="Track Distribution" v-if="stats.courses" />
          <card-table :columns="$options.columns.countries" :rows="stats.countries" title="Top 10 Countries" v-if="stats.countries" />
          <card-table :columns="$options.columns.school" :rows="stats.schools" title="Top 10 Universities" v-if="stats.school" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import CardPageVisits from "@/components/Cards/CardPageVisits.vue";
import CardTable from "@/components/Cards/CardTable.vue";
import swal from 'sweetalert';
export default {
  name: "dashboard-page",
  components: {
    CardPageVisits,
    CardTable,
  },
  columns: {
        school:['School', 'Count'],
        hear_about_us:['Hear About Us', 'Count'],
        availability:['Availability', 'Count'],
        level:['Level of Education', 'Count'],
        reason:['Reason for Joining', 'Count'],
        earning:['Monthly Earning ', 'Count'],
        employment:['Employment Status', 'Count'],
        courses:['Track', 'Count'],
        countries:['Country', 'Count'],
        education:['Level of Education', 'Count'],
      },
  data() {
    return {
      stats:[],
      cohort: 9,
    }
  },
  computed: {
    ...mapGetters({
        user: "auth/user",
    }),
  },
  watch: {
    cohort(){
      return this.fetchStats();
    }
  },
  methods: {
    fetchStats1(url) {
      return axios.get(url);
    },
    fetchStats2(url) {
      return axios.get(url);
    },
    fetchStats3(url) {
      return axios.get(url);
    },
    fetchStats4(url) {
      return axios.get(url);
    },
    fetchStats(){
      const cohort = this.cohort;
      let loader = this.$loading.show();
      Promise.all([this.fetchStats1("admin/auth/stats1/"+cohort), this.fetchStats2("admin/auth/stats2/"+cohort), this.fetchStats3("admin/auth/stats3/"+cohort), this.fetchStats4("admin/auth/stats4/"+cohort)])
      .then((results) => {
        const res1 = results[0].data.payload
        const res2 = results[1].data.payload
        const res3 = results[2].data.payload
        const res4 = results[3].data.payload
        const newRes = Object.assign(res1, res2, res3, res4)
        this.stats = newRes;
        loader.hide()
        return;
      })
      .catch(()=> {
        loader.hide();
        swal({
          title: "Oops!",
          text: "Unable to fetch stats data at the moment. Please refresh ..."
        })
      });
    },
    fetchStats_6(){
      let loader = this.$loading.show();
      Promise.all([this.fetchStats1("admin/auth/v6/stats1"), this.fetchStats2("admin/auth/v6/stats2"), this.fetchStats3("admin/auth/v6/stats3"), this.fetchStats4("admin/auth/v6/stats4")])
      .then((results) => {
        const res1 = results[0].data.payload
        const res2 = results[1].data.payload
        const res3 = results[2].data.payload
        const res4 = results[3].data.payload
        const newRes = Object.assign(res1, res2, res3, res4)
        this.stats = newRes;
        loader.hide()
        return;
      })
      .catch(()=> {
        loader.hide();
        swal({
          title: "Oops!",
          text: "Unable to fetch stats data at the moment. Please refresh ..."
        })
      });
    }
  },
  mounted() {
    this.fetchStats();
  },
};
</script>

<style scoped>
select {
  @apply w-full focus:outline-none focus:border-green-500 focus:rounded-md p-4 focus:border-2 font-medium text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585] focus:ring-0;
}
</style>
