<script setup>
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net-vue3';
// import Select from 'datatables.net-select';
 
// DataTable.use(Select);

import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";

const payments = ref([]);
const isLoading = ref(false);

// eslint-disable-next-line no-unused-vars
const columns = [
    { data: 'created_at' },
    { data: 'updated_at' },
    { data: 'email' },
    { data: 'full_name' },
    { data: 'amount' },
    { data: 'item' },
    { data: 'status' },
    { data: 'reference_id' },
    { data: 'batch_id' },
];

async function fetchPayments () {
    isLoading.value = true;
    try {
        const { data } = await axios.get('admin/auth/payment/all');
        payments.value = data.payload;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
        isLoading.value = false;
    }
}

onMounted(function () {
    fetchPayments();
})
</script>

<template>
  <main>
    <h1 class="font-semibold text-2xl mb-4">All Payments</h1>
    <template v-if="!isLoading">
        <div class="my-24 container" v-if="false">
            <DataTable
                class="display w-full text-sm truncate"
                style="width:100%"
            >
                <thead>
                    <tr>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Email</th>
                        <th>Name</th>
                        <!-- <th>Phone Number</th> -->
                        <th>Amount</th>
                        <th>Item</th>
                        <th>Reference ID</th>
                        <th>Batch</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in payments" :key="index">
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                        <td>{{ data.created_at }}</td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
        <div class="my-24 container">
            <DataTable
                :columns="columns"
                :data="payments"
                class="display w-full text-sm truncate"
                style="width:100%"
            >
                <thead>
                    <tr>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Item</th>
                        <th>Status</th>
                        <th>Reference ID</th>
                        <th>Batch</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
    </template>
  </main>
</template>



<style>
@import 'datatables.net-dt';
</style>