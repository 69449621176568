<template>
  <div>
      <div style="max-width: 400px">
        <vue3-chart-js
          :id="pieChart.id"
          ref="chartRef"
          :type="pieChart.type"
          :data="pieChart.data"
          :options="pieChart.options" 
         />
      </div>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  components: {
    Vue3ChartJs,
  },
  data(){
    return{
        pieChart : {
            id: "pie",
            type: "pie",
            data: {
                labels: [],
                datasets: [
                {
                    backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
                    data: [],
                },
                ],
            },
            options: {
              plugins: {}
            }
        },
        echoMe: [],
        chartRef: null,
    }
  },
  watch:{
    chartVal(){
      console.log(this.chartVal);
      this.sortData()
    }
  },
  methods:{
    sortData(){
      let labels = [];
      let values = []
      if (this.chartVal) {
        this.chartVal.forEach(element => {
          labels.push(Object.values(element)[0])
          values.push(Object.values(element)[1])
        });
        this.pieChart.data.labels = labels;
        this.pieChart.data.datasets[0].data = values;
        this.echoMe = this.pieChart
        this.$refs.chartRef.update(250);
        return;
      } else {
        return;
      }
    }
  },
  props:{
    chartVal : {
        type: Object,
        default(){
            return {}
        },
    }
  }
};
</script>
