<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <details>
      <summary class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              {{title}}
            </h3>
          </div>
        </div>
      </summary>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th v-for="(col, index) in columns" :key="index"
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                {{col}}
              </th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(row, index) in rows" :key="index" class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" v-for="(i, index) in row" :key="index">{{ i ?? "null" }}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </details>
  </div>
</template>
<script>

export default {
  data() {
    return {};
  },
  components: {
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    columns: {
      default() {
        return []
      },
      type: Array,
    },
    title: {
      default: "Title",
      type: String,
    },
    rows: {
      default() {
        return []
      },
      type: Array,
    }
  },
};
</script>

<style scoped>

</style>