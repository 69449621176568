<template>
  <main>
    <h1 class="text-3xl font-bold">User Activity</h1>
    <div v-if="!metricData" class="my-4">
        Fetching Data ...
    </div>
    <table class="w-full mb-12 px-4 mt-4" v-if="metricData && metricData?.length !== 0">
        <tr class="text-left">
            <th>
                Data
            </th>
            <th>
                Count
            </th>
        </tr>
        <tr v-if="metricData?.active_users">
            <td>
                Active Users
            </td>
            <td>
                {{metricData?.active_users ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.active_users_next_batch">
            <td>
                Active Users (Next Batch)
            </td>
            <td>
                {{metricData?.active_users_next_batch ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.total_users_taken_lessons">
            <td>
                Total Users Taken Lessons
            </td>
            <td>
                {{metricData?.total_users_taken_lessons ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.users_taken_lessons_for_week_1">
            <td>
                Total Users Taken Lessons (Week 1)
            </td>
            <td>
                {{metricData?.users_taken_lessons_for_week_1 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.users_taken_lessons_for_week_2">
            <td>
                Total Users Taken Lessons (Week 2)
            </td>
            <td>
                {{metricData?.users_taken_lessons_for_week_2 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.users_taken_lessons_for_week_3">
            <td>
                Total Users Taken Lessons (Week 3)
            </td>
            <td>
                {{metricData?.users_taken_lessons_for_week_3 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.users_taken_lessons_for_week_4">
            <td>
                Total Users Taken Lessons (Week 4)
            </td>
            <td>
                {{metricData?.users_taken_lessons_for_week_4 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.taken_quiz">
            <td>
                Total Users Taken Quiz
            </td>
            <td>
                {{metricData?.taken_quiz ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.taken_quiz_for_week_1">
            <td>
                Total Users Taken Quiz (Week 1)
            </td>
            <td>
                {{metricData?.taken_quiz_for_week_1 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.taken_quiz_for_week_2">
            <td>
                Total Users Taken Quiz (Week 2)
            </td>
            <td>
                {{metricData?.taken_quiz_for_week_2 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.taken_quiz_for_week_3">
            <td>
                Total Users Taken Quiz (Week 3)
            </td>
            <td>
                {{metricData?.taken_quiz_for_week_3 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.taken_quiz_for_week_4">
            <td>
                Total Users Taken Quiz (Week 4)
            </td>
            <td>
                {{metricData?.taken_quiz_for_week_4 ?? "0"}}
            </td>
        </tr>
        <tr v-if="metricData?.current_batch">
            <td>
                Current Batch Users
            </td>
            <td>
                {{metricData?.current_batch ?? "1"}}
            </td>
        </tr>
    </table>
    <div v-if="metricData?.length === 0" class="my-4">
        No Data Available
    </div>
  </main>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            metricData: null,
        }
    },
    methods: {
        async fetchData() {
            try {
                const {data} = await axios.get("admin/auth/metric");
                this.metricData = data.payload;
            } catch (error) {
                console.log(error)
                this.metricData = [];
            }
        }
    },
    mounted(){
        this.fetchData();
    }

}
</script>

<style>

</style>