<template>
  <main>
    <div>
      <form @submit.prevent="addGames()">
        <div class="flex flex-col space-y-6">
          <div class="flex items-center justify-between">
            <div>
              <label for="start_time">
                Game Start Time:
                <input
                  type="datetime-local"
                  name="start_time"
                  id="start_time"
                  v-model="payload.start_time"
                />
              </label>
            </div>
            <button
              id="green"
              class="bg-green-600 text-white font-semibold text-xs px-8"
              @click="showModal(game)"
            >
              Upload CSV
            </button>
          </div>
          <select
            name="week"
            id="week"
            v-model="payload.week"
            class="w-fit p-1 rounded-md text-sm"
          >
            <option v-for="week in [1, 2, 3, 4]" :key="week" :value="week">
              Week {{ week }}
            </option>
          </select>
          <div
            v-for="(question, index) in payload.questions"
            :key="index"
            class="flex flex-col space-y-4 border p-4 rounded-md text-sm"
          >
            <h2>Question {{ index + 1 }}</h2>
            <textarea
              :name="`question_${index}`"
              :id="`question_${index}`"
              cols="30"
              rows="5"
              class="p-2 rounded-md bg-gray-300 border"
              v-model="question.question"
            ></textarea>
            <label :for="`question_${index}_time`">
              Duration (in seconds)
              <input
                :id="`question_${index}_time`"
                type="number"
                min="10"
                max="60"
                v-model="question.time"
              />
            </label>
            <div v-for="(option, index) in question.options" :key="index">
              <span
                class="cursor-pointer"
                @click="question.answer = question.options[index]"
              >
                <i
                  :class="
                    question.answer == question.options[index] &&
                    question.options[index]?.length > 0
                      ? 'far fa-check-circle text-green-600'
                      : 'fas fa-ellipsis-h text-blue-400'
                  "
                ></i>
              </span>
              <input
                class="ml-3 p-1 rounded-md border text-sm"
                type="text"
                v-model="question.options[index]"
              />
            </div>
          </div>

          <div
            @click="addQuestion()"
            class="p-3 my-6 text-sm bg-gray-500 cursor-pointer text-white rounded-md shadow-md w-fit"
          >
            <i class="fas fa-plus text-green-600"></i> Add Question
          </div>
        </div>
        <button
          class="p-3 my-6 text-sm bg-blue-500 text-white rounded-md shadow-md"
        >
          Submit
        </button>
      </form>
    </div>
    <div id="time_modal">
      <form @submit.prevent="reuseAll" class="form">
        <div class="mb-5">
          <label for="start_time">Select New time</label>
          <input
            type="datetime-local"
            name="start_time"
            id="start_time"
            v-model="start_time"
            required
          />
        </div>
        <div class="mb-5 flex flex-col">
          <label>Select Week</label>
          <select
            name="week"
            id="week"
            v-model="payload.week"
            class="w-fit p-1 rounded-md text-sm"
          >
            <option v-for="week in [1, 2, 3, 4]" :key="week" :value="week">
              Week {{ week }}
            </option>
          </select>
        </div>
        <div class="mb-5 flex flex-col">
          <label for="description">Upload Resource</label>
          <input
            class="w-full px-2 py-2 border-none focus:outline-none"
            id="fileControl"
            type="file"
            required
            @change="change"
          />
        </div>
        <div
          id="btn-hld"
          class="flex items-center w-full justify-end gap-10 mt-5"
        >
          <button id="red" class="-mr-5" @click="showModal">CANCEL</button>
          <button
            class="bg-green-600 px-8 py-5 text-white"
            id="green"
            type="submit"
          >
            OK
          </button>
        </div>
      </form>
    </div>
    <div id="drop"></div>
  </main>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      file: null,
      payload: {
        start_time: "",
        week: 1,
        questions: [
          {
            question: "",
            options: ["", "", "", ""],
            answer: "",
            time: 10,
          },
        ],
      },
    };
  },
  methods: {
    change(e) {
      this.file = e.target.files[0];
    },
    async addGames() {
      let loader = this.$loading.show();
      try {
        await axios.post("admin/auth/games/create", this.payload);
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    toggle() {
      document.getElementById("drop").classList.toggle("active");
      document.getElementById("time_modal").classList.toggle("active");
    },
    showModal(week = "") {
      this.week = week;
      this.toggle();
    },
    async handleCsv() {
      const file = this.file;
      const result = await this.extractCsvData(file);
      const payload = {
        start_time: this.start_time,
        week: this.payload.week,
        questions: [],
      };
      const questions = [];
      const time = 30;
      for (let a = 0; a < result.data.length - 1; a++) {
        questions.push({
          question: result.data[a].question,
          options: [
            result.data[a].option_1,
            result.data[a].option_2,
            result.data[a].option_3,
            result.data[a].option_4,
          ],
          answer: result.data[a]["answer"],
          time,
        });
      }
      payload.questions = questions;
      return payload;
    },
    extractCsvData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const rows = data.split("\n");
          const headers = rows[0].split(",");
          const csvData = [];
          for (let i = 1; i < rows.length; i++) {
            const values = rows[i].split(",");
            const row = {};
            for (let j = 0; j < headers.length; j++) {
              row[headers[j]] = values[j];
            }
            csvData.push(row);
          }
          resolve({ headers, data: csvData });
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });
    },
    async reuseAll() {
      this.toggle();
      let loader = this.$loading.show();
      const payload = await this.handleCsv();
      try {
        const { data } = await axios.post(`admin/auth/games/reuse/all`, {
          start_time: this.payload.start_time,
          week: this.payload.week,
        });
        try {
          const req = await axios.post("admin/auth/games/create", payload);
          if (req.status) {
            swal("Game Updataed", "", "success");
          }
          loader.hide();
        } catch (error) {
          loader.hide();
          console.error(error);
        }
        this.allGames = data;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    addQuestion() {
      const newQuestion = {
        question: "",
        options: ["", "", "", ""],
        answer: "",
        time: 10,
      };
      return this.payload?.questions.push(newQuestion);
    },
  },
};
</script>

<style>
.form > * {
  margin-bottom: 5px;
}
#grp button {
  padding-left: 0;
}

#btn-hld {
  margin-top: 20px;
}

#green {
  padding: 5px 10px;
}
#btn-hld > * {
  margin-left: 5px;
}
#red {
  background-color: red;
  color: white;
  margin-left: 5;
  padding-left: 3px;
  padding-right: 3px;
}
button {
  padding: 2px 15px;
}
#time_modal {
  position: fixed;
  width: 300px;
  z-index: 1000;
  top: 50%;
  padding: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  display: none;
  transition: 0.5s ease-in;
}

#time_modal.active {
  display: block;
}

#drop {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: auto; /* overflow-y: hidden; */
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
#drop.active {
  display: block;
}
</style>
