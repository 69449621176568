<template>
  <main>
    <form @submit.prevent="createUser()" class="my-12 border rounded p-6 ">
      <fieldset class="flex flex-col space-y-4">
        <label for="email">Email
          <input type="email" name="email" id="email" v-model="payload.email" />
        </label>
        <label for="first_name">First Name
          <input type="text" name="first_name" id="first_name" v-model="payload.first_name" />
        </label>
        <label for="last_name">Last Name
          <input type="text" name="last_name" id="last_name" v-model="payload.last_name" />
        </label>
        <label for="phone_number">Phone Number
          <input type="tel" name="phone_number" id="phone_number" v-model="payload.phone_number" />
        </label>
        <p class="font-semibold text-lg"> <span class="font-normal">Default Password:</span> {{ payload.password }} <i class="far fa-copy mr-3" @click="copy(payload.password)"></i></p>
        <fieldset class="p-4 border">
          <legend class="font-medium text-lg">Account Scopes</legend>
          <input type="checkbox" id="learning" name="learning" value="learning" v-model="payload.roles" />
          <label for="learning"> Learning</label>
          <input type="checkbox" id="accounts" name="accounts" value="accounts" v-model="payload.roles" />
          <label for="accounts"> Accounts</label>
          <input type="checkbox" id="bootcamp" name="bootcamp" value="bootcamp" v-model="payload.roles" />
          <label for="bootcamp"> Bootcamp</label>
        </fieldset>
        <button type="submit" class="w-fit bg-green-600 rounded-md mx-auto text-white font-semibold text-lg px-4 py-2">Submit</button>
      </fieldset>
    </form>
  </main>
</template>

<script setup>
import { computed, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

const user = computed(()=>{
  return store.state.auth.user;
})

const payload = ref({
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  roles: [],
  update: false
})

const genPassword = () => {
  const string = Math.floor(Math.random() * (99999999 - 11111111 + 1) ) + 111111
  return payload.value.password = 'ADMIN_' + string;
}

// eslint-disable-next-line no-unused-vars
const copy = (string) => {
  const el = document.createElement("textarea");
  el.value = string;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (document.execCommand("copy")) {
      swal({
          text: "Copied",
      });
  }
}

// eslint-disable-next-line no-unused-vars
const createUser = async () => {
  try {
    await axios.post('admin/auth/user/create', payload.value)
    swal({
      title: 'Success!',
      text: 'Admin profile created successfully'
    })
    payload.value = {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      password: '',
      roles: []
    }  
    genPassword();
  } catch (error) {
    if (error?.response?.status == 409) {
      payload.value.update = true;
      swal({
        title: 'Info!',
        text: 'User already exists! Will you like to update account access',
        buttons: {
            cancel: "No",
            confirmButtonText: 'Yes, update',
        },
      }).then((result)=>{
        if (result) {
          return updateUser();
        }
      })
      return;
    }
    swal({
      title: 'Ooops!',
      text: error?.response?.data?.payload ?? 'Unable to create user'
    })
  }
}
// eslint-disable-next-line no-unused-vars
const updateUser = async () => {
  try {
    await axios.post('admin/auth/user/create', payload.value)
    swal({
      title: 'Success!',
      text: 'Admin profile updated successfully'
    })
    payload.value = {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      password: '',
      roles: [],
      update: false
    }  
    genPassword();
  } catch (error) {
    swal({
      title: 'Ooops!',
      text: error?.response?.data?.payload ?? 'Unable to update user'
    })
  }
}

onMounted(()=> {
    if (!user.value.roles.includes('superadmin')) {
        swal({
            title: 'Warning',
            text: 'Unauthorized'
        })
        return router.push('Dashboard');
    }
    genPassword();
})
</script>

<style scoped>
label {
  display: flex;
  flex-direction: column;
}
label input {
  margin-top: 6px;
  padding: 6px;
}
</style>