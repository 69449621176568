export const download = (data, file_name) => {
    //  convert JSON array to csv
    const array = [Object.keys(data[0])].concat(data)
    const result =  array.map(it => {
        return Object.values(it).toString()
    }).join('\n')
    const TIME_STAMP = new Date().toISOString();
    const _filename = `${file_name??''}${TIME_STAMP}`; // file name with time stamp

    // convert CSV to blob file
    const newBlob = new Blob([result], { type: 'application/octet-stream' })
    let url = URL.createObjectURL(newBlob)
    var a = document.createElement("a")
    a.href =  url;
    a.download = _filename + '.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0);
    return true;
};
