<template>
  <main>
    <h1 class="font-semibold text-xl text-blue-600 text-center">
        REFERRAL EARNINGS
    </h1>
    <div class="my-4">
        <label for="type" class="font-medium text-blue-600 pr-4">
            Status:
        </label>
        <select class="rounded-full py-2 px-4 font-medium " name="type" id="type" v-model="status">
            <option :value="false">Pending</option>
            <option :value="true">Paid</option>
        </select>
    </div>
    <table class="table-auto w-full border-separate border-spacing-y-3" id="withdrawalsTable">
      <thead>
        <tr class="py-2 font-body text-sm font-light mb-4 border-y-2  align-top text-left">
          <th>S/N</th>
          <th>Account Name</th>
          <th>Account Number</th>
          <th>Bank</th>
          <th>Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <template v-if="sortWithdrawals?.length > 0">
        <template>
            
        </template>
          <tr 
            v-for="(trx, index) in sortWithdrawals" :key="index"
            class="cursor-pointer border  hover:shadow-lg transition ease-linear py-4 font-body text-sm font-medium mb-4 border-y align-top ">
            <template v-if="index >= ((pageNumber - 1) * countPerPage) && index < (pageNumber * countPerPage)">
                <td class="pl-2">
                {{ index + 1 }}.
                </td>
                <td>
                {{`${trx.account_name}`}}
                </td>
                <td>
                {{`${trx.account_number}`}}
                </td>
                <td>
                {{`${trx.bank_name}`}}
                </td>
                <td>
                {{`${trx.amount}`}}
                </td>
                <td>
                <button disabled v-if="trx.status == true" class="px-3 rounded-full text-[10px] bg-green-500">
                    {{ "Successful" }}
                </button>
                <button @click="confirmPayment(trx.earning_id, trx.trx_reference)" v-else class="px-3 rounded-full text-[10px] bg-orange-700 text-white">
                    {{ "Pending" }}
                </button>
                </td>
            </template>
          </tr>
          <tr >
                <td colspan="6" class="!border-y-0">
                    <div class="flex items-center justify-end w-full">
                        <label for="page" class="font-medium text-blue-600 pr-4">
                                Page:
                        </label>
                        <select class="rounded-full py-2 px-4 font-medium " name="page" id="page" v-model="pageNumber">
                            <option v-for="(page, index) in (Math.ceil(sortWithdrawals.length / countPerPage))" :key="index" :value="page">{{ page }}</option>
                        </select>
                    </div>
                </td>
            </tr>
      </template>
      <tr v-else>
        <td colspan="6" class="text-center !border-y-0">No pending withdrawal requests</td>
      </tr>
    </table>
  </main>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            referralEarnings: [],
            status: false,
            pageNumber: 1,
            countPerPage: 25,
        }
    },
    computed:{
        sortWithdrawals(){
            if (this.referralEarnings.length > 0) {
                return this.referralEarnings.filter((item)=> {
                    return item.status == this.status;
                });
            } else {
                return [];
            }
        }
    },
    methods: {
        async fetchReferralEarnings(){
            let loader = this.$loading.show();
            const { data } = await axios.get('v2/admin/auth/referrals/earnings');
            this.referralEarnings = []
            const RES = data.data;
            RES.forEach((item) => {
                if (item.withdrawals != null) {
                    item.withdrawals.forEach((withDraw) => {
                        withDraw.email = item.user.email
                        withDraw.first_name = item.user.first_name
                        withDraw.last_name = item.user.last_name
                        withDraw.phone_number = item.user.phone_number
                        withDraw.earning_id = item.id
                        withDraw.user_id = item.user_id
                        return this.referralEarnings.push(withDraw);
                    })
                }
            })
            loader.hide()
        },
        async confirmPayment ( id, trx_reference) {
            let loader = this.$loading.show();
            const payload  =  {
                id,
                trx_reference
            };
            const { data } = await axios.post('v2/admin/auth/referrals/pay', payload);
            this.referralEarnings = []
            const RES = data.data;
            RES.forEach((item) => {
                if (item.withdrawals != null) {
                    item.withdrawals.forEach((withDraw) => {
                        withDraw.email = item.user.email
                        withDraw.first_name = item.user.first_name
                        withDraw.last_name = item.user.last_name
                        withDraw.phone_number = item.user.phone_number
                        withDraw.earning_id = item.id
                        withDraw.user_id = item.user_id
                        return this.referralEarnings.push(withDraw);
                    })
                }
            })
            loader.hide();
        }
    },
    mounted(){
        this.fetchReferralEarnings();
    }
}
</script>

<style scoped>
tr td {
    padding: 16px 6px;
    border-top: 2px solid #e4e4e4;
    border-bottom: 2px solid #e4e4e4;
}
</style>