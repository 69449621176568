<template>
  <main>
    <h1 class="font-semibold text-lg my-4">Update Bootcamp Team</h1>
    <form @submit.prevent="updateTeam()" v-if="team" class="grid grid-flow-row gap-4 border p-6">
        <div class="flex flex-col space-y-3">
            <label for="title">Team Title</label>
            <input class="border rounded p-3" id="title" type="text" v-model="team.title" />
        </div>
        <div class="flex flex-col space-y-3">
            <label for="description">Team Description</label>
            <input class="border rounded p-3" id="description" type="text" v-model="team.description" />
        </div>
        <div class="flex flex-col space-y-3">
            <label for="batch_id">Batch</label>
            <input class="border rounded p-3" id="batch_id" type="number" min="1" max="5" v-model="team.batch_id" />
        </div>
        <div class="grid grid-cols-2 gap-6">
            <div v-for="tm in team.teams" :key="tm.id" class="border flex py-6 px-4 justify-around space-x-4">
                <input type="text" v-model="tm.course.title" readonly class="p-2 flex-grow min-w-fit" />
                <input type="number" min="0" v-model="tm.quota" class="p-2" />
            </div>
        </div>
        <button type="submit" class="py-3 px-5 rounded bg-blue-400 text-gray-300">Update</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { inject, onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
const $loading = inject("$loading")

const route = useRoute();
const router = useRouter();

const team = ref(null);

const fetchTeamDetails = async (id) => {
    const loader = $loading.show();
    try {
        const {data} = await axios.get(`admin/auth/teams/${id}`)
        console.log(data.payload)
        team.value = data.payload
        loader.hide();
    } catch (error) {
        console.error(error);
        loader.hide();
    }
}

// eslint-disable-next-line no-unused-vars
const updateTeam = async () => {
    const loader = $loading.show();
    const id = team.value.id;
    try {
        const {data} = await axios.put(`admin/auth/teams/${id}`, team.value);
        team.value = data.payload;
        loader.hide();
        swal({
            title: "Success",
            text: "Team Details updated successfully"
        })
    } catch (error) {
        loader.hide();
        console.error(error);
    }
}

onMounted(() => {
    if (!route.params?.id) {
        return router.push({name: 'StartUpTeams'});
    }
    fetchTeamDetails(route?.params?.id);
})
</script>

<style>

</style>