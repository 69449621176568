<script setup>
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net-vue3';
// import Select from 'datatables.net-select';
 
// DataTable.use(Select);

import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";

const winners = ref([]);
const isLoading = ref(false);

// eslint-disable-next-line no-unused-vars
const columns = [
    { data: 'created_at' },
    { data: 'user.email' },
    { data: 'user.first_name' },
    { data: 'user.last_name' },
    { data: 'user.phone_number' },
    { data: 'item.gift' },
];

async function fetchReferralWinners () {
    isLoading.value = true;
    try {
        const { data } = await axios.get('admin/auth/referrals');
        winners.value = data.payload;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
        isLoading.value = false;
    }
}

// eslint-disable-next-line no-unused-vars
const convertToCSV = (arr, filename) => {
    const array = [Object.keys(arr[0])].concat(arr)
    const result =  array.map(it => {
        return Object.values(it).toString()
    }).join('\n')
    const TIME_STAMP = new Date().toISOString();
    const _filename = `${filename??''}${TIME_STAMP}`;
    handleDownload(result, _filename)
};

const handleDownload = (file, filename) => {
    const newBlob = new Blob([file], { type: 'application/octet-stream' })
    let url = URL.createObjectURL(newBlob)
    var a = document.createElement("a")
    a.href =  url;
    a.download = filename + '.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0);
}

onMounted(function () {
    fetchReferralWinners();
})
</script>

<template>
  <main>
    <h1 class="font-semibold text-2xl mb-4">All Referral Winners</h1>
    <template v-if="!isLoading">
        <div class="my-24 container">
            <DataTable
                :columns="columns"
                :data="winners"
                class="display w-full text-sm truncate"
                style="width:100%"
            >
                <thead>
                    <tr>
                        <th>Created At</th>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone Number</th>
                        <th>Gift</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
    </template>
  </main>
</template>



<style>
@import 'datatables.net-dt';
</style>