<script setup>
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net-vue3';
// import Select from 'datatables.net-select';
 
// DataTable.use(Select);

import { ref } from "@vue/reactivity";
import { computed, inject, onMounted } from "@vue/runtime-core";
import axios from "axios";

const reviews = ref([]);
// const isLoading = ref(false);

const $loading = inject("$loading");

// eslint-disable-next-line no-unused-vars
const columns = [
    { data: 'batch_id' },
    { data: 'week' },
    { data: 'rating' },
    { data: 'total_reviews' },
];

// eslint-disable-next-line no-unused-vars
const ratings = [ 
                    {
                        id: 1,
                        title: 'Frustrating',
                        icon: 'fa-solid:angry',
                        color: '#F34747'
                    },
                    {
                        id:2,
                        title: 'Bad',
                        icon: 'bi:emoji-frown-fill',
                        color: '#FA8F27'
                    },
                    {
                        id: 3,
                        title: 'Average',
                        icon: 'bi:emoji-expressionless-fill',
                        color: '#FECF0A'
                    },
                    {
                        id:4,
                        title: 'Good',
                        icon: 'bi:emoji-smile-fill',
                        color: '#87D33D'
                    },
                    {
                        id:5,
                        title: 'Awesome',
                        icon: 'bi:emoji-heart-eyes-fill',
                        color: '#52A350'
                    }
            ]

// eslint-disable-next-line no-unused-vars
const overallRating = computed(()=>{
    if(!reviews.value.overall) return;
    const rate = ratings.find((item)=>{
        return item.id === reviews.value.overall;
    });
    return `<span class="iconify text-3xl md:text-4xl inline" style="color: ${rate.color}" data-icon="${rate.icon}"></span> ${rate.title}`
})

async function fetchReviews () {
    const loader = $loading.show();
    try {
        const { data } = await axios.get('admin/auth/feedback');
        reviews.value = data.payload;
        loader.hide();
        console.log(reviews.value)
    } catch (error) {
        console.error(error);
        loader.hide();
    }
}

onMounted(function () {
    fetchReviews();
})
</script>

<template>
  <main>
    <h1 class="font-semibold text-2xl mb-4">All Reviews</h1>
        <template v-if="reviews.overall">
            <h1 class="my-8 text-2xl">The average overall experience is <span class="font-bold" v-html="overallRating"></span> </h1>
        </template>
        <div class="mb-24 container">
            <DataTable
                :columns="columns"
                :data="reviews.breakdown"
                class="w-full text-sm"
                style="width:100%"
            >
                <thead>
                    <tr>
                        <th>Batch ID</th>
                        <th>Week</th>
                        <th>Average Rating</th>
                        <th>Total Reviews</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
  </main>
</template>



<style>
@import 'datatables.net-dt';
</style>