<template>
    <div>
        <strong>Search for user by E-mail</strong>
        <form @submit.prevent="searchUser" class="grid grid-flow-row gap-8">
            <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                placeholder="Enter intern email address..."
                class="rounded my-4"
            />
            <button
                class="rounded-md bg-emerald-600 p-2 text-white font-medium my-2"
                type="submit"
            >
                Search...
            </button>
        </form>
        <div class="w-full flex space-x-12 my-8 p-4" v-if="user?.email">
            <img :src="user.avatar" :alt="user.first_name" class="h-56 w-56 rounded-full border" />
            <div>
                <h1 class="font-semibold text-3xl text-blue-800 underline mb-2">User Details</h1>
                <div class="space-y-1">
                    <h2 class="font-bold text-2xl">{{ `${user?.first_name} ${user?.last_name}` }}</h2>
                    <p class="font-bold text-lg">{{ user.user_id ?? " " }}</p>
                    <p class="font-medium text-lg">{{ user.email }}</p>
                    <p class="font-medium text-lg">{{ user.phone_number ?? " " }}</p>
                    <p class="font-medium text-lg">{{ user?.account?.account_number ? `Raven Account - ${user?.account?.account_number}` : "" }}</p>
                </div>
                <h1 class="font-semibold text-3xl text-blue-800 underline mt-6 mb-2">Learning Details</h1>
                <div class="space-y-1 mb-4" v-for="batch in user.batches" :key="batch.id">
                    <h3 class="text-xl">
                        <strong>{{batch.batch.title}}</strong> - {{ batch.course.title }}
                    </h3>
                    <div v-if="batch.scores.length > 0" class="ml-6">
                        <ul v-for="score in batch.scores" :key="score.id">
                            <li class="font-medium border-b border-b-[#0f0f0f49] pb-1 ">{{`Week ${score.week} quiz score: `}} {{score.score}}%</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="user?.length === 0" class="font-semibold text-red-500 my-8">
            User not found.
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            email: "",
            user: null,
        };
    },
    methods: {
        async searchUser() {
            const data = {
                email: this.email,
            };
            try {
                this.user = null;
                axios
                    .post("admin/auth/user/search", data)
                    .then((res) => {
                        this.user = res.data.payload;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.user = [];
                    });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
table td {
    border: 1px solid #ddd;
    padding: 8px;
}
table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}
</style>
