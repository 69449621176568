/* eslint-disable */
<template>
  <div>
    <div v-if="stats?.length === 0" class="font-semibold text-lg text-blueGray-700 mb-4 px-4">Loading ...</div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12">
        <div class=" min-w-full">
            Learning Registration 
            <vue3-chart-js v-bind="lineChart" v-if="lineChart.data.labels.length >= 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import axios from "axios";
// import swal from 'sweetalert';

export default {
  data() {
    return {
      stats:[],
      cohort: 7,
      lineChart: {
            type: "line",
            data: {
                labels: [],
                datasets: [],
            },
        }
    }
  },
  watch:{
    stats(){
      this.sortData()
    }
  },
  components: {
    Vue3ChartJs
  },
  methods: {
    async fetchStats(){
      let loader = this.$loading.show();
      const { data } = await axios.get('admin/auth/stats/cohort8')
      this.stats = data.payload;
      loader.hide();
    },
    sortData(){
      let labels = [];
      Object.keys(this.stats.self_paced).forEach(key => {
        labels.push(this.stats.self_paced[key].date);
        });
      Object.keys(this.stats.instructor).forEach(key => {
        labels.push(this.stats.instructor[key].date);
        });
      labels = Array.from(new Set(labels))
      let self_paced = {
        data:[],
        label: "Self Paced",
        fill: false,
        borderColor: "green",
        tension: 0.5,
        backgroundColor: "green",
      };
      this.stats.self_paced.forEach(element => {
        self_paced.data.push(element.count)
      });
      let instructor = {
        data:[],
        label: "Instructor",
        fill: false,
        borderColor: "blue",
        tension: 0.5,
        backgroundColor: "blue",
      };
      this.stats.instructor.forEach(element => {
        instructor.data.push(element.count)
      });
      this.lineChart.data.labels = labels;
      this.lineChart.data.datasets = [self_paced, instructor]
    }
  },
  mounted() {
    this.fetchStats();
  },
};
</script>
