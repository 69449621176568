import axios from "axios";
export default {
    namespaced: true,
    state: {
        token: null,
        user: "",
    },

    getters: {
        user(state) {
            return state.user;
        },
        token(state) {
            return state.token;
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
    },

    actions: {
        /**
         * Get auth user's data
         * To set user and course state
         * @param {Object} {commit,state}
         * @param {object} payload
         */
        async attempt({ commit, state }, token) {
            if (token) {
                commit("SET_TOKEN", token);
            }
            if (!state.token) {
                return;
            }
            try {
                let response = await axios.get("admin/auth");
                commit("SET_USER", response.data.payload);
            } catch (e) {
                commit("SET_TOKEN", null);
                commit("SET_USER", "");
            }
        },
        signOut({ commit }) {
            localStorage.removeItem("token")
            
            commit("SET_TOKEN", null);
            commit("SET_USER");
            // });
        },
    },
};
