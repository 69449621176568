<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">Post Announcements to Interns Dashboard</h1>
    <form @submit.prevent="postAnnouncement()" class="my-4 space-y-4 grid grid-flow-row gap-4">
        <label for="title" class="font-semibold mb-2">
            Announcement Title:
        </label>
        <input required type="text" placeholder="Enter announcement title here..." name="title" id="title" v-model="payload.title" class="mb-2" />
        <label for="description" class="font-semibold mb-2">
            Announcement Description:
        </label>
        <input required type="text" placeholder="Enter announcement description here..." name="description" id="description" v-model="payload.description" class="mb-2" />
        <label for="course" class="font-semibold mb-2">
            Track:
        </label>
        <select required name="course" id="course" v-model="payload.course_id">
            <option value="0" selected>General</option>
            <option :value="course.id" v-for="course in courses" :key="course.id">
                {{ course.title }}
            </option>
        </select>
        <label for="close_date" class="font-semibold mb-2">
            Expires On:
        </label>
        <input required type="datetime-local" name="close_date" id="close_date" v-model="payload.expires_on">
        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4">Post Announcement</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import swal from "sweetalert";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";

const payload = ref({
    title: "",
    description: "",
    course_id: "",
    expires_on: ""
})

const isLoading = ref(false);
const courses = ref([]);

// eslint-disable-next-line
const postAnnouncement = async () => {
    if (payload.value.title.trim().length === 0 ||
        payload.value.description.trim().length === 0 ||
        payload.value.expires_on.trim().length === 0) {
        swal({
        title: "Oops",
        text: "Fill in all fields"
       })
       return;
    }
    isLoading.value = true;
    try {
       await axios.post("admin/auth/announcement", payload.value);
       swal({
        title: "Success",
        text: "Announcement Posted"
       })
       payload.value.title = "";
       payload.value.description = "";
       payload.value.course_id = "";
       payload.value.expires_on = "";
       isLoading.value = false;
    } catch (error) {
        console.log(error)
        swal({
            title: "Oops!",
            text: "Unable to post announcement. Please try again later."
        })
       isLoading.value = false;
    }
};
const fetchCourses = async () => {
    try {
        const {data} = await axios.get("courses");
        courses.value = data.payload;
    } catch (error) {
        console.log(error)
        swal({
            title: "Oops!",
            text: "Please refresh the page."
        })
    }
}

onMounted(() => {
    fetchCourses()
})
</script>

<style scoped>
input, select {
border-radius: 7px;
}

</style>