<template>
  <div>
      <div class="w-full xl:w-11/12 mb-12 xl:mb-0 px-4">
        <h1 class="font-semibold text-2xl mb-12">Export Users Data</h1>
        <main class="w-full px-4">
            <details>
                <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Registration Data</summary>
                <div class="pl-4 ml-3">
                    <details>
                        <summary class="cursor-pointer mb-4 text-xl" @click="downloadCSV('completed')">
                            Completed Registrations
                        </summary>
                        <div v-if="completedReg?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${completedReg?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(completedReg, 'completed_reg')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details >
                        <summary class="cursor-pointer mb-4 text-xl" @click="downloadCSV('incomplete')">Incomplete Reg</summary>
                        <div v-if="incompleteReg?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${incompleteReg?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(incompleteReg, 'incomplete_reg')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="cursor-pointer mb-4 text-xl" @click="downloadCSV('total')">
                            Total Registrations
                        </summary>
                        <div v-if="totalReg?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${totalReg?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(totalReg, 'total_registrations')">
                                Download Data
                            </div>
                        </div>
                    </details>
                </div>
            </details>
            <details>
                <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Raven Data</summary>
                <div class="pl-4 ml-3">
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('hasRaven')">
                            Users Have Raven
                        </summary>
                        <div v-if="hasRavenBank?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasRavenBank?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasRavenBank, 'users_with_raven')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('noRaven')">
                            Completed Reg w/o Raven
                        </summary>
                        <div v-if="noRavenBank?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${noRavenBank?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(noRavenBank, 'without_raven')">
                                Download Data
                            </div>
                        </div>
                    </details>
                </div>
            </details>
            <details>
                <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Jobberman  Data</summary>
                <div class="pl-4 ml-3">
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('jobbermanEnrolled')">
                            Enrolled on Side Hustle Website
                        </summary>
                        <div v-if="hasEnrolledJobberman?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasEnrolledJobberman?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasEnrolledJobberman, 'enrolled_for_jobberman')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('jobbermanNotEnrolled')">
                            Eligible to Enroll (but yet to enroll)
                        </summary>
                        <div v-if="hasNotEnrolledJobberman?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasNotEnrolledJobberman?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasNotEnrolledJobberman, 'yet_to_enroll_jobberman')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('jobbermanBaseline')">
                            Taken Baseline Assessment
                        </summary>
                        <div v-if="hasTakenJobbermanBaseline?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasTakenJobbermanBaseline?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasTakenJobbermanBaseline, 'jobberman_baseline')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('jobbermanEndline')">
                            Taken Endline Assessment
                        </summary>
                        <div v-if="hasTakenJobbermanEndline?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasTakenJobbermanEndline?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasTakenJobbermanEndline, 'incomplete_reg')">
                                Download Data
                            </div>
                        </div>
                    </details>
                </div>
            </details>
            <details>
                <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Side Hustle Baseline Test</summary>
                <div class="pl-4 ml-3">
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('takebaseline')">
                            Taken Baseline
                        </summary>
                        <div v-if="hasTakenSHBaseline?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${hasTakenSHBaseline?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(hasTakenSHBaseline, 'taken_sidehustle_baseline')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('passbaseline')">
                            Passed Baseline
                        </summary>
                        <div v-if="passedSHBaseline?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${passedSHBaseline?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(passedSHBaseline, 'passed_sidehustle_baseline')">
                                Download Data
                            </div>
                        </div>
                    </details>
                    <details>
                        <summary class="rounded cursor-pointer mb-4 text-xl" @click="downloadCSV('failbaseline')">
                            Failed Baseline
                        </summary>
                        <div v-if="failedSHBaseline?.length > 0" class="grid grid-flow-col justify-between gap-12 pb-4 mb-4 border-b-2">
                            <h1 class="font-bold text-lg">{{`${failedSHBaseline?.length ?? ""} records`}}</h1>
                            <div class="rounded cursor-pointer text-white font-semibold bg-red-400 p-2" @click="convertToCSV(failedSHBaseline, 'failed_sidehustle_baseline')">
                                Download Data
                            </div>
                        </div>
                    </details>
                </div>
            </details>
            <template v-if="true">
                <details v-for="(batch, index) in [6,7,8,9]" :key="index" class="flex flex-row flex-wrap justify-between space-y-8 space-x-6">
                    <summary class="font-bold text-2xl text-[#E3E3E3] mb-8">Cohort {{batch}}</summary>
                    <button  @click="downloadCSV(`batch`, batch)" class="rounded cursor-pointer mb-4 text-white font-semibold bg-red-400 p-4  mx-12">
                        All Cohort {{batch}} Members
                    </button>
                    <template v-if="courses?.length > 0">
                        <button v-for="course in courses" :key="course.id" @click="downloadCSV(`course`, batch, course.id, course.title)" class="rounded cursor-pointer mb-4 text-white font-semibold bg-red-400 p-4  mx-12">
                            {{ course?.title ?? " " }}
                        </button>
                    </template>
                </details>
            </template>
        </main>
        <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
    data(){
        return {
            batches:[],
            courses:[],
            isLoading: false,
            completedReg: null,
            incompleteReg: null,
            totalReg: null,
            hasRavenBank: null,
            noRavenBank: null,
            hasEnrolledJobberman: null,
            hasNotEnrolledJobberman: null,
            hasTakenJobbermanBaseline: null,
            hasTakenJobbermanEndline: null,
            hasTakenSHBaseline: null,
            failedSHBaseline: null,
            passedSHBaseline: null,
        }
    },
    methods:{
        downloadCSV(id, batch, course, title){
            try {
                this.isLoading = true;
                let data = {
                    action: id
                }
                if (batch) {
                    data.cohort = batch
                }
                if (course) {
                    data.course_id = course
                }

                axios.post("admin/auth/data/download", data)
                .then((res) => {
                    const filename = `${id}_${batch??''}_${title??''}`;
                    switch (id) {
                        case "completed":
                            this.completedReg = res.data.payload
                            break;
                        case "incomplete":
                            this.incompleteReg = res.data.payload
                            break;
                        case "total":
                            this.totalReg = res.data.payload
                            break;
                        case "hasRaven":
                            this.hasRavenBank = res.data.payload
                            break;
                        case "noRaven":
                            this.noRavenBank = res.data.payload
                            break;
                        case "jobbermanEnrolled":
                            this.hasEnrolledJobberman = res.data.payload
                            break;
                        case "jobbermanNotEnrolled":
                            this.hasNotEnrolledJobberman = res.data.payload
                            break;
                        case "jobbermanBaseline":
                            this.hasTakenJobbermanBaseline = res.data.payload
                            break;
                        case "jobbermanEndline":
                            this.hasTakenJobbermanEndline = res.data.payload
                            break;
                        case "takebaseline":
                            this.hasTakenSHBaseline = res.data.payload
                            break;
                        case "passbaseline":
                            this.passedSHBaseline = res.data.payload
                            break;
                        case "failbaseline":
                            this.failedSHBaseline = res.data.payload
                            break;
                        default:
                            this.convertToCSV(res.data.payload, filename);
                            break;
                    }
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err)
                    this.isLoading = false;
                })
            } catch (error) {
                console.log(error)
                this.isLoading = false;
            }
        },
        convertToCSV(arr, filename) {
            const array = [Object.keys(arr[0])].concat(arr)
            const result =  array.map(it => {
                return Object.values(it).toString()
            }).join('\n')
            const TIME_STAMP = new Date().toISOString();
            const _filename = `${filename??''}${TIME_STAMP}`;
            this.handleDownload(result, _filename)
        },
        handleDownload(file, file_name) {
            const newBlob = new Blob([file], { type: 'application/octet-stream' })
            let url = URL.createObjectURL(newBlob)
            var a = document.createElement("a")
            a.href =  url;
            a.download = file_name + '.csv';
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0);
        },
        async fetchBatch(){
            try {
                axios.get("admin/auth/data/batch")
                .then((res) => {
                    this.batches = res.data.payload.batches
                    this.courses = res.data.payload.courses
                })
                .catch((err) => {
                    console.log(err)
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted(){
        this.fetchBatch();
    }
}
</script>

<style>
summary {
cursor: pointer;
}

</style>