<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <div class="container py-12">
      <BackButton />
      <div class="flex items-center justify-between">
        <div>
          <h1 class="font-header-1 font-bold text-3xl md:text-4xl text-black">
            Task
          </h1>
          <p class="font-medium mt-2 text-body-text">Add Task</p>
        </div>

        <button
          class="bg-[#000] text-white p-3 rounded-lg"
          @click="handleUpload"
        >
          Upload CSV
        </button>
        <input type="file" id="file" @change="extractCSV" hidden />
      </div>
      <form
        @submit.prevent="handleSubmit"
        class="grid gap-4 grid-flow-row my-8 border rounded-md p-4"
      >
        <div class="flex flex-col space-y-2">
          <label for="title">Task</label>
          <textarea
            type="text"
            id="task"
            rows="5"
            class="border border-blue-600 bottom-1 rounded-lg px-3 pt-3"
            placeholder="Enter Task information"
            v-model="task"
            required
          ></textarea>
        </div>
        <div class="flex flex-col space-y-2">
          <label for="description">Instruction</label>
          <textarea
            type="text"
            required
            rows="5"
            id="instruction"
            class="border border-blue-600 bottom-1 rounded-lg px-3 pt-3"
            v-model="instruction"
            placeholder="Enter Task Intruction"
          ></textarea>
        </div>
        <div class="flex flex-col space-y-2">
          <label for="week">Select Week</label>
          <select
            v-model="week"
            value="Select Week"
            name="week"
            id="week"
            required
            class="select"
          >
            <option value="" class="p-5">Select Week</option>
            <template v-for="week in weeks" :key="week?.value" class="p-5">
              <option :value="week?.value">{{ week?.name }}</option>
            </template>
          </select>
        </div>
        <div class="flex flex-col space-y-2" v-if="courses">
          <label for="week">Select Course</label>
          <select
            v-model="course_id"
            value="Select Course"
            name="week"
            id="week"
            required
          >
            <option value="">Select Course</option>
            <template v-for="course in courses" :key="course?.id">
              <option :value="course?.id">{{ course?.title }}</option>
            </template>
          </select>
        </div>

        <!-- <div class="flex flex-col space-y-2">
                    <label for="url">Video URL</label>
                    <input type="url" required v-model="liveClass.url" id="url" placeholder="Enter the url to the live class recording Eg. https://drive.google.com/file/d/1eZjdmWsMfaAkcBLcjPA9ZnbtpzDS9Gsq/view?usp=sharing" />
                </div> -->
        <div class="flex flex-col space-y-2">
          <label for="batch_id">Batch</label>
          <select
            name="batch_id"
            id="week"
            value="Select Batch"
            required
            v-model="batch_id"
          >
            <option value="">Select Batch</option>
            <option value="1">Alpha Batch</option>
            <option value="2">Bravo Batch</option>
            <option value="3">Charlie Batch</option>
            <option value="4">Delta Batch</option>
            <option value="6">Latest</option>
          </select>
        </div>

        <!-- <VueProgressBar /> -->
        <button type="submit" class="text-black">Add</button>
      </form>
      <!-- <vue-progress-bar></vue-progress-bar> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { createToast } from "mosha-vue-toastify";
import * as XLSX from "xlsx";

// import RadialProgressBar from "vue3-radial-progress";
// import { createToast } from "mosha-vue-toastify";
export default {
  data() {
    return {
      weeks: [
        {
          name: "week 1",
          value: "1",
        },
        {
          name: "week 2",
          value: "2",
        },
        {
          name: "week 3",
          value: "3",
        },
        {
          name: "week 4",
          value: "4",
        },
      ],
      data: [],
      //   file: null,
      task: "",
      instruction: "",
      week: "",
      module: "",
      course_id: "",
      batch_id: "",
      courses: null,
      lessons: null,
      newData: null,
      hashCourse: null,
      completedSteps: 0,
      totalSteps: 100,
      file: null,
    };
  },
  mounted() {
    this.fetchCourse().then((data) => {
      this.courses = data.payload;
      this.hashCourse = this.courses.reduce(function (map, obj) {
        map[obj.id] = obj.title;
        return map;
      }, {});
      console.log(this.hashCourse);
      this.fetchLessons().then((data) => {
        this.lessons = data.payload;
        const newData = data.payload?.videos?.map((vid) => {
          for (var i = 0; i < data.payload.url.length; i++) {
            if (vid.id === data.payload.url[i].lesson_id) {
              return {
                ...vid,
                course: this.getCourse(vid.course_id),
                ...data.payload.url[i],
              };
            }
          }
        });
        this.newData = newData;
      });
    });
  },
  methods: {
    reset() {
      this.task = "";
      this.instruction = "";
      this.week = "";
      this.course_id = "";
      this.batch_id = "";
    },

    async postData(url, data) {
      for (const item of data) {
        try {
          const response = await axios.post(url, item);
          console.log(response);
          createToast("Task Uploaded", { type: "success" });
        } catch (error) {
          console.log(error);
        }
      }
    },

    async handleSubmit() {
      let loader = this.$loading.show();
      const formData = new FormData();
      formData.append("task", this.task);
      formData.append("instruction", this.instruction);
      formData.append("course_id", this.course_id);
      formData.append("batch_id", this.batch_id);
      formData.append("week", this.week);

      try {
        const res = await axios.post("tasks", formData);
        if (res.status) {
          swal("Task added succesfully", "", "success");
          this.reset();
          // createToast("Task created succesfully", { type: "success" });
          loader.hide();
        }
      } catch (error) {
        // createToast("Sorry an error occured", { type: "danger" });
        this.reset();
        swal("Oops!", "Something went wrong!", "error");
        console.log(error);
        loader.hide();
      }
    },

    async fetchCourse() {
      const res = await axios.get("courses");
      return res.data;
    },

    extractCSV(e) {
      e.preventDefault();
      this.handleCsv(e.target.files[0]);
    },
    arrayToObjects(data, keys) {
      return data.map((row) => {
        return row.reduce((obj, value, index) => {
          obj[keys[index]] = value;
          return obj;
        }, {});
      });
    },

    async handleCsv(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
        });
        this.data = data.slice(1);
      };
      reader.readAsBinaryString(file);
      console.log(this.data);
      if (this.data.length < 1) {
        return createToast("Please load file again", { type: "info" });
      }
      const keys = ["week", "task", "instruction", "batch", "learning_path"];
      const jsonData = this.arrayToObjects(this.data, keys);
      const nData = jsonData.map((data) => {
        return {
          week: data.week,
          instruction: data.instruction,
          batch_id: data.batch,
          task: data.task,
          course_id: this.getCourseId(data.learning_path),
        };
      });

      this.postData("tasks", nData);
    },

    handleUpload() {
      document.getElementById("file").click();
    },

    async fetchLessons() {
      const res = await axios.get("mentor/auth/lessons/lesson");
      return res.data;
    },
    getCourse(id) {
      if (this.courses) {
        const crse = this.courses.filter((course) => course.id === id);
        return crse[0]?.title;
      }
    },
    getCourseId(cours) {
      console.log(cours);
      console.log(this.courses);
      if (this.courses) {
        const crse = this.courses.filter(
          (course) => course.title.toLowerCase() === cours.toLowerCase()
        );
        console.log(crse);
        return crse[0]?.id;
      }
    },
  },
};
</script>

<style scoped>
button {
  background-color: #059669 !important;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}
#week {
  padding: 10px !important;
}

#upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

#upload.active {
  display: block;
}

/* input,
select {
  @apply bg-gray-100 text-body-text border rounded-md p-20;
}

label {
  @apply font-semibold text-body-text;
} */
</style>
