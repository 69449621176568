<template>
  <main>
    Hello
    <div v-if="uniqueItems?.length > 0" class="gap-6 grid grid-cols-2">
      <div
        v-for="game in uniqueItems"
        :key="game"
        class="rounded-lg shadow-md p-4 bg-white space-y-4"
      >
        <div id="grp" class="flex items-center justify-between">
          <button
            class="text-red-400 font-semibold text-xs"
            @click="deleteAll(game)"
          >
            DELETE
          </button>
          <button
            class="text-green-600 font-semibold text-xs"
            @click="showModal(game)"
          >
            REUSE
          </button>
        </div>

        <h3 class="font-medium text-sm">WEEK {{ game }} Group</h3>
        <p
          class="underline text-blue-700 cursor-pointer"
          @click="redirect(game)"
        >
          View
        </p>
      </div>
    </div>
    <div v-else>No Game yet</div>
    <div id="time_modal">
      <form @submit.prevent="reuseAll">
        <label for="start_time">Select New time</label>
        <input
          type="datetime-local"
          name="start_time"
          id="start_time"
          v-model="start_time"
          required
        />
        <div
          id="btn-hld"
          class="flex items-center w-full justify-end gap-10 mt-5"
        >
          <button id="red" class="-mr-5" @click="showModal">CANCEL</button>
          <button class="bg-green-600 px-8 py-5 text-white" type="submit">
            OK
          </button>
        </div>
      </form>
    </div>
    <div id="drop"></div>
  </main>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      uniqueItems: null,
      start_time: "",
      week: "",
    };
  },
  methods: {
    redirect(val) {
      return this.$router.push({
        name: "ViewAllGamesPage",
        params: { id: val },
      });
    },
    toggle() {
      document.getElementById("drop").classList.toggle("active");
      document.getElementById("time_modal").classList.toggle("active");
    },
    showModal(week = "") {
      this.week = week;
      this.toggle();
    },
    filterData(data) {
      const weekz = data.payload.map((p) => p.week);
      this.uniqueItems = [...new Set(weekz)];
    },
    async fetchAllGames() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("admin/auth/games");
        this.filterData(data);
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async deleteAll(week) {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get(`admin/auth/games/delete/all/${week}`);
        swal("Delete succesfull", "", "success");
        this.filterData(data);
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async reuseAll() {
      this.toggle();
      let loader = this.$loading.show();
      const payload = {
        start_time: this.start_time,
        week: this.week,
      };
      try {
        const { data } = await axios.post(`admin/auth/games/reuse/all`, {
          start_time: this.start_time,
          week: this.week,
        });
        const questions = data.payload.map((e) => {
          return {
            question: e.question,
            options: JSON.parse(e.options),
            answer: e.answer,
            time: e.time,
          };
        });
        payload["questions"] = questions;
        try {
          const req = await axios.post("admin/auth/games/create", payload);
          if (req.status) {
            swal("Reuse succesfull", "", "success");
          }
          loader.hide();
        } catch (error) {
          loader.hide();
          console.error(error);
        }
        this.allGames = data;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
  mounted() {
    this.fetchAllGames();
  },
};
</script>

<style scoped>
#grp button {
  padding-left: 0;
}

#btn-hld {
  margin-top: 20px;
}

#btn-hld > * {
  margin-left: 5px;
}
#red {
  background-color: red;
  color: white;
  margin-left: 5;
}
button {
  padding: 2px 15px;
}
#time_modal {
  position: fixed;
  width: 300px;
  z-index: 1000;
  top: 50%;
  padding: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  display: none;
  transition: 0.5s ease-in;
}

#time_modal.active {
  display: block;
}

#drop {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: auto; /* overflow-y: hidden; */
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
#drop.active {
  display: block;
}
</style>
