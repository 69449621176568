<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="font-bold text-2xl my-4">
        Update Internship Site Settings
    </h1>
    <form @submit.prevent="updateSettings()" class="my-4 grid grid-cols-2 gap-x-8 gap-y-4">
        <div>
            <label for="current_week" class="font-semibold my-2">
                Current Week:
            </label>
            <select required name="current_week" id="current_week" v-model="payload.current_week">
                <option :value="week" v-for="week in [0,1,2,3,4]" :key="week">
                    {{ `Week ${week}` }}
                </option>
            </select>
        </div>
        <div v-if="false">
            <label for="current_batch" class="font-semibold my-2" v-if="false">
                Current Batch:
            </label>
            <select required name="current_batch" id="current_batch" v-model="payload.current_batch" v-if="false">
                <option :value="batch" v-for="batch in [1,2,3,4,5]" :key="batch">
                    {{ `Batch ${batch}` }}
                </option>
            </select>
        </div>
        <div v-if="false">
            <label for="video_mode" class="font-semibold my-2">
                Video Mode:
            </label>
            <select required name="video_mode" id="video_mode" v-model="payload.video_mode">
                <option value="vimeo">Vimeo</option>
                <option value="DO">Digital Ocean</option>
            </select>
        </div>
        <div>
            <label for="slack_workplace_link" class="font-semibold my-2">
                Slack Link:
            </label>
            <input type="url" name="slack_workplace_link" id="slack_workplace_link" v-model="payload.slack_workplace_link" class="mb-2" />
        </div>
        <div>
            <label for="telegram_link" class="font-semibold my-2">
                Telegram Link:
            </label>
            <input type="url" name="telegram_link" id="telegram_link" v-model="payload.telegram_link" class="mb-2" />
        </div>
        <div>
            <label for="cut_off_score" class="font-semibold my-2">
                Cut-Off Score:
            </label>
            <input type="number" name="cut_off_score" id="cut_off_score" v-model="payload.cut_off_score" class="mb-2" />
        </div>
        <div>
            <label for="certificate_payment_deadline" class="font-semibold my-2">
                Certificate Payment Deadline:
            </label>
            <input type="date" name="certificate_payment_deadline" id="certificate_payment_deadline" v-model="payload.certificate_payment_deadline" class="mb-2" />
        </div>
        <div class="flex items-start pt-4">
            <label for="lesson_week_guard" class="my-2 font-semibold ">
                Activate Week guards:
            </label>
            <div class="flex flex-col ml-3">
                <label for="lesson_week_guard" class="switch">
                    <input type="checkbox"  name="lesson_week_guard" id="lesson_week_guard" v-model="payload.lesson_week_guard">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.lesson_week_guard ? "Enabled" : "Disabled"}}</span>
            </div>
        </div>
        <fieldset class="col-span-2 grid grid-cols-3 gap-8 border-2 p-6 pt-0">
            <legend class="text-lg font-medium">Payment Gateway Switches</legend>
            <div class="flex flex-col items-start pt-4">
                <label for="raven_payment_active" class="my-2">
                    Raven Bank Transfer:
                </label>
                <label for="raven_payment_active" class="switch">
                    <input type="checkbox"  name="raven_payment_active" id="raven_payment_active" v-model="payload.raven_payment_active">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.raven_payment_active ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="wema_payment_active" class="my-2">
                    WEMA Bank Transfer:
                </label>
                <label for="wema_payment_active" class="switch">
                    <input type="checkbox"  name="wema_payment_active" id="wema_payment_active" v-model="payload.wema_payment_active">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.wema_payment_active ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="flutter_bank_payment_active" class="my-2">
                    Flutterwave Bank Transfer:
                </label>
                <label for="flutter_bank_payment_active" class="switch">
                    <input type="checkbox"  name="flutter_bank_payment_active" id="flutter_bank_payment_active" v-model="payload.flutter_bank_payment_active">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.flutter_bank_payment_active ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="flutter_card_payment_active" class="my-2">
                    Flutterwave Card Payment:
                </label>
                <label for="flutter_card_payment_active" class="switch">
                    <input type="checkbox"  name="flutter_card_payment_active" id="flutter_card_payment_active" v-model="payload.flutter_card_payment_active">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.flutter_card_payment_active ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="paystack_active" class="my-2">
                    Paystack Payment:
                </label>
                <label for="paystack_active" class="switch">
                    <input type="checkbox"  name="paystack_active" id="paystack_active" v-model="payload.paystack_active">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.paystack_active ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="raven_virtual_payment" class="my-2">
                    Raven Virtual Accounts:
                </label>
                <label for="raven_virtual_payment" class="switch">
                    <input type="checkbox"  name="raven_virtual_payment" id="raven_virtual_payment" v-model="payload.raven_virtual_payment">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.raven_virtual_payment ? "Enabled" : "Disabled"}}</span>
            </div>
            <div class="flex flex-col items-start pt-4">
                <label for="mint_payment" class="my-2">
                    Mint transfer:
                </label>
                <label for="mint_payment" class="switch">
                    <input type="checkbox"  name="mint_payment" id="mint_payment" v-model="payload.mint_payment">
                    <span class="slider"></span>
                </label>
                <span class="font-semibold">{{payload.mint_payment ? "Enabled" : "Disabled"}}</span>
            </div>
        </fieldset>
        
        <button type="submit" class="font-semibold text-white bg-emerald-600 rounded p-4 my-4 col-span-2">Update Settings</button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import swal from "sweetalert";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();

// eslint-disable-next-line
const isLoading = ref(false);

// eslint-disable-next-line no-unused-vars
const user = computed(() =>{
    return store.state.auth.user;
})
// eslint-disable-next-line
const payload = ref({
    current_week: "",
    cut_off_score: "",
    slack_workplace_link: "",
    telegram_link: "",
    video_mode: "",
    current_batch: "",
    certificate_payment_deadline: "",
    raven_payment_active: "",
    wema_payment_active: "",
    flutter_bank_payment_active: "",
    flutter_card_payment_active: "",
    paystack_active: "",
    raven_virtual_payment: "",
    mint_payment: "",
    lesson_week_guard: ""
})

const fetchSiteSettings = async () => {
    try {
        const {data} = await axios.get("settings");
        const res = data.payload
        const settings = payload.value;
        settings.current_week = res.current_week 
        settings.cut_off_score = res.cut_off_score 
        settings.slack_workplace_link = res.slack_workplace_link 
        settings.telegram_link = res.telegram_link 
        settings.video_mode = res.video_mode 
        settings.current_batch = res.current_batch 
        settings.certificate_payment_deadline = res.certificate_payment_deadline 
        settings.raven_payment_active = !!res.raven_payment_active 
        settings.wema_payment_active = !!res.wema_payment_active 
        settings.flutter_bank_payment_active = !!res.flutter_bank_payment_active 
        settings.flutter_card_payment_active = !!res.flutter_card_payment_active 
        settings.paystack_active = !!res.paystack_active 
        settings.raven_virtual_payment = !!res.raven_virtual_payment 
        settings.mint_payment = !!res.mint_payment 
        settings.lesson_week_guard = !!res.lesson_week_guard 
       

    } catch (error) {
        console.log(error)
        swal({
            title: "Oops!",
            text: "Please refresh this page"
        })
    }
}

// eslint-disable-next-line
const updateSettings = async () => {
    try {
        isLoading.value = true;
        const { data } = await axios.post("admin/auth/settings/update", payload.value);
        console.log(data.payload)
        swal({
            title: "Success!",
            text: "Settings Updated Successfully"
        })
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        console.log(error)
        swal({
            title: "Oops!",
            text: "Please try again later"
        })
    }
}

onMounted(() => {
    fetchSiteSettings();
})
</script>


<style scoped>
input, select {
border-radius: 7px;
padding: 4px 8px;
font-size: 14px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>