<template>
  <main>
    <h1 class="font-semiold text-2xl mb-4">Challenge Winners</h1>
    <table v-if="winners?.length > 0" class="w-full table-auto border-collapse border-2 border-slate-500 px-4">
        <button v-if="winners?.length>0"  @click="convertToCSV(winners,'challenge')" class="rounded cursor-pointer mb-4 text-white font-semibold bg-red-400 p-4  mx-12">
            Download Data
        </button>
        <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Prize</th>
            <th>Batch</th>
            <th>Week</th>
        </tr>
        <tr v-for="(winner, index) in winners" :key="index">
            <td>{{`${winner.first_name} ${winner.last_name}`}}</td>
            <td>{{winner.email}}</td>
            <td>{{winner.phone_number}}</td>
            <td>{{winner.prize}}</td>
            <td>{{winner.batch_id}}</td>
            <td>{{winner.week}}</td>
        </tr>
    </table>
    <h2 v-if="!winners">
        Loading ...
    </h2>
    <h2 v-if="winners?.length === 0">
        Data not available
    </h2>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";

const winners = ref(null);

const fetchWinners = async () => {
    try {
        const { data } = await axios.get("admin/auth/winners/challenge")
        winners.value = data.payload
    } catch (error) {
        winners.value = []
        console.log(error)
    }
}

// eslint-disable-next-line no-unused-vars
const convertToCSV = (arr, filename) => {
    const array = [Object.keys(arr[0])].concat(arr)
    const result =  array.map(it => {
        return Object.values(it).toString()
    }).join('\n')
    const TIME_STAMP = new Date().toISOString();
    const _filename = `${filename??''}${TIME_STAMP}`;
    handleDownload(result, _filename)
};

const handleDownload = (file, filename) => {
    const newBlob = new Blob([file], { type: 'application/octet-stream' })
    let url = URL.createObjectURL(newBlob)
    var a = document.createElement("a")
    a.href =  url;
    a.download = filename + '.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0);
}

onMounted(() => {
    console.log(winners.value)
    fetchWinners();
})
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>