<template>
  <div>
    <button id="reuse" class="bg-green-600 text-white" @click="setUse">
      Reuse
    </button>
    <main class="mt-3">
      <div v-if="allGames?.length > 0" class="gap-6 grid grid-cols-2">
        <div
          v-for="game in allGames"
          :key="game.id"
          class="rounded-lg shadow-md p-4 bg-white space-y-4"
        >
          <div id="grp2" class="">
            <button
              class="text-red-400 font-semibold text-xs float-right"
              @click="deleteGame(game?.id)"
            >
              DELETE
            </button>
            <button
              class="text-green-600 font-semibold text-xs float-right"
              @click="showModal(game?.id)"
            >
              EDIT
            </button>
          </div>
          <h3 class="font-medium text-sm">
            WEEK {{ game?.week }} - QUESTION {{ game?.round }}
          </h3>
          <p v-html="game.question"></p>
          <ul class="w-3/4 border rounded-lg p-3">
            <small class="underline">options</small>
            <li
              v-for="(option, index) in JSON.parse(game?.options)"
              :key="index"
              v-html="option"
              :class="
                option == game?.answer
                  ? 'bg-green-200 font-semibold'
                  : 'font-medium'
              "
            ></li>
          </ul>
        </div>
      </div>
      <div id="time_modal">
        <!-- <form> -->
        <div class="flex flex-col space-y-4 border p-4 rounded-md text-sm">
          <h2>Question</h2>
          <textarea
            :name="`question_${index}`"
            :id="`question_${index}`"
            cols="30"
            rows="5"
            class="p-2 rounded-md bg-gray-300 border"
            v-model="question"
          ></textarea>
          <label :for="`question_${index}_time`">
            Duration (in seconds)
            <input type="number" min="10" max="60" v-model="time" />
          </label>
          <div v-for="(option, index) in options" :key="index">
            <span class="cursor-pointer" @click="answer = options[index]">
              <i
                :class="
                  answer == options[index] && options[index]?.length > 0
                    ? 'far fa-check-circle text-green-600'
                    : 'fas fa-ellipsis-h text-blue-400'
                "
              ></i>
            </span>
            <input
              class="ml-3 p-1 rounded-md border text-sm"
              type="text"
              v-model="options[index]"
            />
          </div>
        </div>
        <div
          id="btn-hld"
          class="flex items-center w-full justify-end gap-10 mt-5"
        >
          <button id="red" class="-mr-5" @click="showModal()">CANCEL</button>
          <button
            id="green"
            class="bg-green-600 px-8 py-5 text-white"
            @click="updateOne"
          >
            OK
          </button>
        </div>
        <!-- </form> -->
      </div>
      <div id="time_modal2">
        <form @submit.prevent="reuseAll">
          <div class="flex flex-col">
            <label for="start_time">Select New time</label>
            <input
              type="datetime-local"
              name="start_time"
              id="start_time"
              v-model="start_time"
              required
            />
          </div>
          <div
            id="btn-hld"
            class="flex items-center w-full justify-end gap-10 mt-5"
          >
            <button id="red" class="-mr-5" @click="showModal2">CANCEL</button>
            <button
              id="green"
              class="bg-green-600 px-8 py-5 text-white"
              type="submit"
            >
              OK
            </button>
          </div>
        </form>
      </div>
      <div id="drop"></div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      allGames: null,
      edit: "",
      start_time: "",
      question: "",
      options: ["", "", "", ""],
      answer: "",
      time: "",
    };
  },
  methods: {
    toggle() {
      document.getElementById("drop").classList.toggle("active");
      document.getElementById("time_modal").classList.toggle("active");
    },
    toggle2() {
      document.getElementById("drop").classList.toggle("active");
      document.getElementById("time_modal2").classList.toggle("active");
    },

    showModal(id = "") {
      this.edit = id;
      console.log(this.edit);
      this.toggle();
    },

    showModal2() {
      this.toggle2();
    },

    reset() {
      this.edit = "";
      this.question = "";
      this.options = ["", "", "", ""];
      this.answer = "";
      this.time = "";
    },
    async fetchAllGames() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get(
          "admin/auth/games/" + this.$route.params.id
        );
        this.allGames = data.payload;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async updateOne() {
      this.toggle();
      let loader = this.$loading.show();
      console.log({
        id: this.edit,
        question: this.question,
        options: this.options,
        answer: this.answer,
        week: this.$route.params.id,
      });

      try {
        const { data } = await axios.post(`admin/auth/games/update`, {
          id: this.edit,
          question: this.question,
          options: this.options,
          answer: this.answer,
          week: this.$route.params.id,
          time: this.time,
        });
        swal("Edit Succesfull", "", "success");
        this.allGames = data.payload;
        loader.hide();
        this.reset();
      } catch (error) {
        swal("Something went Wrong", "", "error");
        loader.hide();
        console.error(error);
        this.reset();
      }
    },
    setUse() {
      this.toggle2();
    },
    async reuseAll() {
      this.toggle2();
      let loader = this.$loading.show();
      const payload = {
        start_time: this.start_time,
        week: this.$route.params.id,
      };
      try {
        const { data } = await axios.post(`admin/auth/games/reuse/all`, {
          start_time: this.start_time,
          week: this.$route.params.id,
        });
        const questions = data.payload.map((e) => {
          return {
            question: e.question,
            options: JSON.parse(e.options),
            answer: e.answer,
            time: e.time,
          };
        });
        payload["questions"] = questions;
        try {
          const req = await axios.post("admin/auth/games/create", payload);
          if (req.status) {
            swal("Reuse succesfull", "", "success");
          }
          this.fetchAllGames();
          loader.hide();
        } catch (error) {
          loader.hide();
          console.error(error);
        }
        this.allGames = data;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },

    async deleteGame(id) {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get(`admin/auth/games/delete/${id}`);
        this.allGames = data.payload;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    editGame() {},
  },
  mounted() {
    this.fetchAllGames();
  },
};
</script>

<style>
#grp button {
  padding-left: 0;
}
#btn-hld {
  margin-top: 20px;
}
#btn-hld > * {
  margin-left: 5px;
}

#start_time {
  width: 250px;
  display: inline-block;
  margin-top: 20px;
}
#reuse {
  padding: 5px 25px;
  border-radius: 5px;
}

#grp2 {
  display: flex;
  gap: 5;
  align-items: center;
  justify-content: space-between;
}

#grp button {
  padding-left: 0;
}
#btn-hld {
  margin-top: 20px;
}
#btn-hld > * {
  margin-left: 5px;
}

#green {
  padding: 3px 12px;
}
#red {
  background-color: red;
  color: white;
  margin-left: 5;
  padding: 3px 12px;
}

#time_modal,
#time_modal2 {
  position: fixed;
  min-width: 300px;
  width: 500px;
  z-index: 1000;
  top: 50%;
  padding: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  display: none;
  transition: 0.5s ease-in;
}
#time_modal.active,
#time_modal2.active {
  display: block;
}
#drop {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: auto; /*
overflow-y: hidden; */
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
#drop.active {
  display: block;
}
</style>
