<template>
    <main>
        <div class="flex items-stretch space-x-4 p-6">
            <select name="cohort" id="cohort" class="p-2 border rounded" v-model="cohort">
                <option value="8">Cohort 8</option>
                <option value="9">Cohort 9</option>
                <option value="10">Cohort 10</option>
            </select>
            <Datepicker 
                range
                v-model="dataSortDateRange"
                lang="en"
            />
        </div>
        <div class="w-full grid grid-cols-2 gap-12" v-if="dataFetch">
            <table class="w-full border p-2" v-for="(dataset, index) in dataFetch.payments" :key="index">
                <caption class="uppercase">{{ index.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") }}</caption>
                <thead class="text-left">
                    <tr class="py-2 font-body text-sm font-light mb-4 border-y-2  align-top text-left">
                        <th>Item</th>
                        <th>Count</th>
                        <th>Revenue</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer border  hover:shadow-lg transition ease-linear py-4 font-body text-sm font-medium mb-4 border-y align-top " v-for="(count, _index) in dataFetch.payments[index]" :key="_index">
                        <td class="uppercase">{{ _index.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") }}</td>
                        <td>{{ count.count }}</td>
                        <td v-if="count.revenue">{{ `&#8358; ${Intl.NumberFormat("en-US").format(count.revenue)}` }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full border p-2" v-for="(dataset, index) in dataFetch.course_registrations" :key="index">
                <caption class="uppercase">{{ index.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") }}</caption>
                <thead class="text-left">
                    <tr class="py-2 font-body text-sm font-light mb-4 border-y-2  align-top text-left">
                        <th>Track</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer border  hover:shadow-lg transition ease-linear py-4 font-body text-sm font-medium mb-4 border-y align-top " v-for="(course, _index) in dataFetch.course_registrations[index]" :key="_index">
                        <td>{{ course.title }}</td>
                        <td>{{ course.count }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full border p-2" >
                <caption class="uppercase">{{ "Referral Stats" }}</caption>
                <thead class="text-left">
                    <tr class="py-2 font-body text-sm font-light mb-4 border-y-2  align-top text-left">
                        <th>Title</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer border  hover:shadow-lg transition ease-linear py-4 font-body text-sm font-medium mb-4 border-y align-top " v-for="(count, _index) in dataFetch.referrals" :key="_index">
                        <td class="uppercase">{{ _index.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") }}</td>
                        <td>{{ count }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full border p-2" >
                <caption class="uppercase">{{ "Learning Insight" }}</caption>
                <thead class="text-left">
                    <tr class="py-2 font-body text-sm font-light mb-4 border-y-2  align-top text-left">
                        <th>Title</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer border  hover:shadow-lg transition ease-linear py-4 font-body text-sm font-medium mb-4 border-y align-top " v-for="(count, _index) in dataFetch.learning" :key="_index">
                        <td class="uppercase">{{ _index.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") }}</td>
                        <td>{{ count }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>
<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import axios from 'axios'
export default {
    components: {
      Datepicker: VueDatepickerUi
    },
    data() {
        return {
            dataFetch: [],
            cohort: 9,
            dataSortDateRange: [
                new Date('2023-09-27'),
                new Date()
            ],
        }
    },
    watch:{
        cohort(){
            this.fetchData();
        },
        dataSortDateRange(){
            this.fetchData();
        }
    },
    methods: {
        async fetchData() {
            let loader = this.$loading.show()
            try {
                let start_date = new Date(this.dataSortDateRange[0]).toISOString();
                let end_date = new Date(this.dataSortDateRange[1]).toISOString();
                const { data } = await axios.get(`v2/admin/auth/data/marketing/${this.cohort}?start_date=${start_date}&end_date=${end_date}`);
                this.dataFetch = data.data;
                loader.hide();
            } catch (error) {
                loader.hide()
                console.error(error);
            }
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>
tr td {
    padding: 16px 6px;
    border-top: 2px solid #e4e4e4;
    border-bottom: 2px solid #e4e4e4;
}
</style>