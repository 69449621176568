<template>
  <main>
    <div class="fixed top-0 bottom-0 left-0 right-0 min-h-screen" style="background-color:#0000006E;min-width:100vw;" v-if="isLoading"></div>
    <h1 class="text-3xl font-bold">Quiz Stats Data</h1>
    <h2 v-if="!stats" class="text-lg" style="margin-top:12px">Fetching Your data...</h2>
    <template v-if="stats">
        <details v-if="stats?.batch_1" class="my-4">
            <summary class="text-2xl font-bold">Batch 1</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Taken Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_1?.taken?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_1?.taken" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('1', data.week, 'taken')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Passed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_1?.passed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_1?.passed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('1', data.week, 'passed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Failed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_1?.failed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_1?.failed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('1', data.week, 'failed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <details v-if="stats?.batch_2" class="my-4">
            <summary class="text-2xl font-bold">Batch 2</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Taken Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_2?.taken?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_2?.taken" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('2', data.week, 'taken')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Passed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_2?.passed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_2?.passed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('2', data.week, 'passed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Failed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_2?.failed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_2?.failed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('2', data.week, 'failed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <details v-if="stats?.batch_3" class="my-4">
            <summary class="text-2xl font-bold">Batch 3</summary>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Have Taken Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_3?.taken?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_3?.taken" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('3', data.week, 'taken')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Passed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_3?.passed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_3?.passed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('3', data.week, 'passed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
            <details class="pl-4 ml-4">
                <summary class="font-semibold text-xl">Users Who Failed Quiz</summary>
                <table class="w-full table-auto border-collapse border-2 border-slate-500 px-4" v-if="stats?.batch_3?.failed?.length > 0">
                    <tr>
                        <th>
                            Week
                        </th>
                        <th>
                            Count
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    <tr v-for="(data, index) in stats?.batch_3?.failed" :key="index">
                        <td>{{`Week ${data.week}`}}</td>
                        <td>{{data.count}}</td>
                        <td>
                            <button class="p-2 bg-red-400 rounded mb-4 font-semibold" @click="downloadData('3', data.week, 'failed')">Download Data</button>
                        </td>
                    </tr>
                </table>
                <h2 v-else>No Data Available</h2>
            </details>
        </details>
        <h2 v-else class="my-4 py-4">No Data Available</h2>
    </template>
  </main>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import swal from "sweetalert";
const stats = ref(null)
const isLoading = ref(false);

const fetchStats = async () => {
    try {
        isLoading.value = true;
        const { data } = await axios.get("admin/auth/learning/stats")
        stats.value = data.payload
        isLoading.value = false;
    } catch (error) {
        swal({
            title: "Error",
            text: error?.response?.data?.payload ?? "Please Try Again Later",
        })
        isLoading.value = false;
        stats.value = []
    }
};

const downloadData = async (batch, week, status) => {
    if (!batch || !week || !status) {
        return;
    }
    try {
        isLoading.value = true;
        const { data } = await axios.post("admin/auth/learning/download", {batch:batch, week:week, status:status})
        const filename = `batch_${batch}_week_${week}_${status}`
        convertToCSV(data.payload, filename)
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        swal({
            title: "Error",
            text: error?.response?.data?.payload ?? "Please Try Again Later",
        })
    }
};

const convertToCSV = (arr, filename) => {
        const array = [Object.keys(arr[0])].concat(arr)
        const result =  array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
        const TIME_STAMP = new Date().toISOString();
        const _filename = `${filename??''}${TIME_STAMP}`;
        handleDownload(result, _filename)
};

const handleDownload = (file, file_name) => {
    const newBlob = new Blob([file], { type: 'application/octet-stream' })
    let url = URL.createObjectURL(newBlob)
    var a = document.createElement("a")
    a.href =  url;
    a.download = file_name + '.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0);
};

downloadData();
onMounted(() => {
    fetchStats();
})
</script>

<style scoped>
table td, table th {
border: 1px solid #ddd;
padding: 8px;
text-align: center;
}

table {margin: 12px 0;}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}
</style>