import { createApp } from "vue";
import axios from "axios";
import store from "./store";
import router from "./router";

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import "mosha-vue-toastify/dist/style.css";

// axios defaults
axios.defaults.baseURL =
    process.env.NODE_ENV == "development"
        ? "http://127.0.0.1:8000/api/"
        : "https://backend.terrahq.co/api/";

/**
 * Store Subscribe
 */
store.subscribe((mutation) => {
    switch (mutation.type) {
        case "auth/SET_TOKEN":
            if (mutation.payload) {
                axios.defaults.headers[
                    "Authorization"
                ] = `Bearer ${mutation.payload}`;
                localStorage.setItem("token", mutation.payload);
            } else {
                axios.defaults.headers["Authorization"] = null;
                localStorage.removeItem("token");
            }
            break;
    }
});

axios.defaults.headers["app-version"] = localStorage.version || 1;

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // if (error.response.status == 401) {
        //     localStorage.removeItem("token");
        //     location.reload();
        // }
        if (error.response.status == 426) {
            localStorage.setItem("version", error.response.data.payload);
            location.reload();
        }
        return Promise.reject(error);
    },
);


router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        let authUser = store.getters["auth/user"];
        if (authUser) {
            next();
        } else {
            next({ name: "Login" });
        }
    } else {
        next();
    }
});

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mounting point for the whole app

import App from "@/App.vue";


store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
    createApp(App)
        .use(router)
        .use(store)
        .use(VueLoading, {
            isFullPage: true,
            loader: "dots",
            backgroundColor: "#00000",
            color: "#52A350",
        })
        .mount("#app");
});
