<template>
    <div>
      <div>
          <form @submit.prevent="storeTeam()" class="space-y-4 border-2 p-8">
            <div class=" flex flex-col space-y-2"> 
                  <label for="batch_id">Batch</label>
                  <select id="batch_id" required v-model="bootcamp.batch_id">
                      <option value="1">Alpha Batch</option>
                      <option value="2">Bravo Batch</option>
                      <option value="3">Charlie Batch</option>
                      <option value="4">Delta Batch</option>
                      <option selected value="5">Zulu Batch</option>
                  </select>
              </div>
              <div class=" flex flex-col space-y-2">
                  <label for="title">Start Date</label>
                  <input type="text" id="title" required v-model="bootcamp.start_date" />
              </div>
              <div class=" flex flex-col space-y-2">
                  <label for="title">End Date</label>
                  <input type="text" id="title" required v-model="bootcamp.end_date" />
              </div>
              <button type="submit" class="text-white font-bold bg-emerald-600 mt-12 rounded" style="padding:12px">Submit</button>
          </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import swal from 'sweetalert';
  export default {
      data() {
          return {
              bootcamp:{
                  start_date: "",
                  end_date: "",
                  batch_id: "",
              },
          }
      },

      methods:{
          async storeTeam(){
              let loader = this.$loading.show();
              try {
                  await axios.post("admin/auth/bootcamp/dates", this.bootcamp)
                  swal({
                      title: "Success",
                      text: "Bootcamp Date Updated successfully"
                  })
                  this.bootcamp = {
                        start_date: "",
                        end_date: "",
                        batch_id: "",
                    },
                  loader.hide();
              } catch (error) {
                  console.error(error);
                  swal({
                      title: "Ooops!!!",
                      text: "Unable to update the date  at the moment. Please try again shortly"
                  })
                  loader.hide();
              }
          },
      
      },
  }
  </script>
  
  <style scoped>
  
  input , select, textarea {
  border-radius: 6px;
  padding: 4px;
  margin-top: 6px;
  margin-bottom: 12px;
  border: 1px solid;
  }
  
  label {
  font-weight: 600;
  }
  
  input[type='checkbox']{
  margin-right: 6px;
  }
  
  </style>